
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModalWindow",
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  created() {
    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.$emit("close");
      }
    });
  },
  unmounted() {
    document.removeEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.$emit("close");
      }
    });
  },
});
