export enum Actions {
  getAllPrices = "GET_ALL_PRICES",
  getCVPrices = "GET_CV_PRICES",
  bid = "BID",
  login = "LOGIN",
  logout = "LOGOUT",
  register = "REGISTER",
  updateAccount = "UPDATE_ACCOUNT",
  checkLogin = "CHECK_LOGIN",
  mailchimpSubcribe = "MAILCHIMP_SUBSCRIBE",
  getCountries = "GET_COUNTRIES",
  getCounties = "GET_COUNTIES",
  getPhonePrefixes = "GET_PHONE_PREFIXES",
  listenPrices = "LISTEN_PRICES",
  resetPassword = "RESET_PASSWORD",
  pollVote = "POLL_VOTE",
  sendEmailVerify = "SEND_EMAIL_VERIFY",
  syncTime = "SYNC_TIME",
}
