<template>
  <header class="page__header">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <router-link
            :to="{ name: 'Home', hash: '#footer' }"
            class="page__header__link condensed"
            >Back</router-link
          >
        </div>
      </div>
    </div>
  </header>
  <div class="container page">
    <div class="row">
      <div class="col text-center">
        <h1>About</h1>
        <p>
          Winthorpe Ventures are a collection of friends that decided to group
          together to bid on Steve Jobs’ Job application when it went to auction
          in late March 2021. We were fortunate enough to win the auction. That
          is where this journey began.
        </p>
        <p>
          We’re an eclectic bunch ranging from 24-71 years old, all from
          different walks of life. But with one thing in common:
        </p>
        <p class="strong">
          We want to challenge the status-quo and tread a new path into the
          unknown.
        </p>
        <p>
          We bought the application not only because it is an incredible piece
          of history, but we wanted to use it as a vehicle to help understand
          and push the boundaries of the art/collectible and NFT world. How do
          the two worlds interact with each other? How much do they overlap and
          crossover? Do they actually complement each other?
        </p>
        <p>
          Most importantly, with the astronomical growth of NFTs, where does
          true value now sit within these markets?
        </p>
        <p class="italic">
          The Physical, the Digital or can it co-exist in both as separate
          assets?
        </p>
        <p>That has brought us to where we are now.</p>
        <p>What will happen?</p>
        <p>We literally haven’t got a clue.</p>
        <a href="mailto:info@stevejobsjobapplication.com">Get in touch</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "About",
  created() {
    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.goBack();
      }
    });
  },
  unmounted() {
    document.removeEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.goBack();
      }
    });
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
});
</script>

<style scoped lang="scss"></style>
