<template>
  <header :class="['header', { shadow: isScrolled }]">
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <a
            href="#"
            class="nav__toggle condensed"
            @click.prevent="showMenu = !showMenu"
            >Menu</a
          >
          <transition name="transmenu">
            <nav
              v-if="showMenu"
              ref="nav"
              class="nav"
              @mouseleave="showMenu = false"
            >
              <a
                v-scroll-to="{
                  el: 'body',
                  onStart: () => {
                    showMenu = false;
                  },
                }"
                href="#"
                class="nav__item"
                >Home</a
              >
              <a
                v-scroll-to="{
                  el: '#auction',
                  offset: -92,
                  onStart: () => {
                    showMenu = false;
                  },
                }"
                href="#"
                class="nav__item"
                >Auction</a
              >
              <a
                v-scroll-to="{
                  el: '#manifest',
                  offset: -92,
                  onStart: () => {
                    showMenu = false;
                  },
                }"
                href="#"
                class="nav__item"
                >Manifesto</a
              >
              <a
                v-scroll-to="{
                  el: '#piece',
                  offset: -92,
                  onStart: () => {
                    showMenu = false;
                  },
                }"
                href="#"
                class="nav__item"
                >The Piece</a
              >
              <router-link :to="{ name: 'About' }" class="nav__item"
                >About</router-link
              >

              <router-link :to="{ name: 'Faq' }" class="nav__item"
                >FAQ</router-link
              >
              <a
                href="#"
                class="nav__item show-xs"
                @click.prevent="showShareModal = true"
                >Share</a
              >
              <div class="nav__close" @click.prevent="showMenu = false"></div>
            </nav>
          </transition>
        </div>
        <div class="col">
          <h1 class="header__title">Steve Jobs’ Job Application</h1>
        </div>
        <div class="col-2 text-right">
          <a
            href="#"
            class="header__share condensed hide-xs"
            @click.prevent="showShareModal = true"
            >Share</a
          >
        </div>
      </div>
    </div>
  </header>
  <div id="home" class="container">
    <div class="row">
      <div class="col claim">
        <h2>Is it time for<br />different thinking?</h2>
        <h3>The world’s first physical vs NFT auction</h3>
        <div ref="countdown" class="claim__countdown">
          <p class="condensed">
            Auction ended
          </p>
          <div
            class="timer"
          >
            <p>
              <span class="condensed">00</span>
              <span class="desc">Days</span>
            </p>
            <p class="condensed">:</p>
            <p>
              <span class="condensed">00</span>
              <span class="desc">Hours</span>
            </p>
            <p class="condensed">:</p>
            <p>
              <span class="condensed">00</span>
              <span class="desc">Minutes</span>
            </p>
            <p class="condensed">:</p>
            <p>
              <span class="condensed">00</span>
              <span class="desc">Seconds</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      id="auction"
      class="row justify-content-center justify-content-md-between"
    >
      <div class="col-xl-5 col-md-6 col-10 auction">
        <h2>Physical</h2>
        <div class="auction__image">
          <div
            class="sold"
          >
            <p>Lot Finished</p>
          </div>
          <img
            src="static/application.jpg"
            alt="Steve Jobs Job Application"
            @click="showZoomModal = true"
            @click.right.prevent
          />
        </div>
        <AuctionDetail :auction-data="lotPrices.physical" />
        <div class="auction__logo row">
          <div class="col"><span>Powered by</span></div>
          <div class="col text-right">
            <a
              href="https://snoofa.com/?utm_source=projectjobs&utm_medium=referral&utm_campaign=projectjobs&utm_content=powered"
              target="_blank"
            >
              <img src="static/logos/SnoofaMock.png" alt="Snoofa" />
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-5 col-md-6 col-10 auction">
        <h2>Digital</h2>
        <div class="auction__image">
          <div
            class="sold"
          >
            <p>Lot Finished</p>
          </div>
          <img
            src="static/application.jpg"
            alt="Steve Jobs Job Application"
            @click="showZoomModal = true"
            @click.right.prevent
          />
        </div>
        <AuctionDetail :auction-data="lotPrices.digital" is-digital />
        <div class="auction__logo row">
          <div class="col"><span>Powered by</span></div>
          <div class="col text-right">
            <a href="https://rarible.com/" target="_blank">
              <img src="static/logos/rarible.svg" alt="Rarible" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h3>Authenticated by</h3>
        <div class="authenticate">
          <a
            href="https://www.psacard.com/cert/ae01173"
            class="authenticate__logo"
            target="_blank"
          >
            <div class="authenticate__image">
              <img src="static/logos/psa-logo_blk.png" alt="PSA" />
            </div>
            Cert: #AE01173
          </a>
          <a
            href="https://www.beckett-authentication.com/verify-certificate"
            class="authenticate__logo"
            target="_blank"
          >
            <div class="authenticate__image">
              <img
                src="static/logos/beckett-authetication-logo_blk.png"
                alt="Beckett"
              />
            </div>
            Cert: #A77006
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h2>Value meter</h2>
        <div class="progress__wrapper">
          <h3>Result</h3>
          <div class="progress__bar">
            <div
              class="progress__bar__physical text-left"
              :style="{ 'flex-basis': priceRatio + '%' }"
            >
              Paper
            </div>
            <div class="progress__bar__digital text-right">NFT</div>
          </div>
          <h4>Physical vs. Digital</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <AssetsPoll />
      </div>
    </div>
  </div>
  <div class="market">

  </div>
  <div id="manifest" class="container manifesto">
    <div class="row">
      <div class="col text-center">
        <h2>Manifesto</h2>
        <p>
          Will this open a whole new market for decentralized collectibles? Will
          this cement the status-quo? Or could we see a stalemate with a fresh
          understanding that both can coexist and even complement one another as
          we traverse the next 50 years of innovation?
        </p>
        <p>
          Whatever the outcome, the worlds of finance and art will be watching
          with anticipation.
        </p>
      </div>
    </div>
  </div>
  <div class="press">
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <h2>Press</h2>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <a
            href="https://hypebeast.com/2021/7/steve-jobs-job-application-nft-physical-auction"
            target="_blank"
          >
            <img src="static/logos/hypebeast.png" alt="Hypebeast" />
          </a>
          <a href="https://www.telegraph.co.uk/" target="_blank">
            <img
              src="static/logos/telegraph.png"
              alt="The Telegraph"
            />
          </a>
          <a href="https://www.entrepreneur.com/article/378188" target="_blank">
            <img
              src="static/logos/ent.png"
              alt="Entrepreneur Europe"
            />
          </a>
          <a href="https://www.theguardian.com/international" target="_blank">
            <img
              src="static/logos/1920px-The_Guardian_2018.svg.png"
              alt="The Guardian"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
  <div id="piece" class="container piece">
    <div class="row">
      <div class="col text-center">
        <h2>The Piece</h2>
        <p>
          This is a unique piece of history from the exact moment that a dreamer
          changed the world.
        </p>
        <p>
          It’s a snapshot into the mind of a future genius at a moment where any
          small deviation from the path ahead, would have meant a very different
          world today.
        </p>
        <a
          href="#"
          class="button secondary"
          @click.prevent="showInformation = !showInformation"
          ><span>{{ showInformation ? "Less" : "More" }} information</span></a
        >
        <transition name="information">
          <div v-if="showInformation" class="piece__information">
            <p>
              As we approach the 50th anniversary of Steve Jobs putting pen to
              paper to apply for potentially his first job, less than a decade
              before he went on to lay some of the key foundations of our
              current digital world, we’re now at a crossroad that he’s
              undoubtedly influenced. Which do we value more, The Physical or
              The Digital?
            </p>
            <p>
              There’s been a tectonic shift with digital assets soaring in their
              preva- lence and in their value. Conversations have been focused
              on whether digital assets hold true value. However, for the first
              time, we will put identical assets to auction side-by-side as we
              look to challenge propo- nents of the physical and the digital
              world to place their trust in one or the other. Simply put, which
              do you value more as we look to the future?
            </p>
            <p>
              There’s an argument within art and collectibles, that value lies
              in the ownership itself - not the experience. Therefore, if
              ownership is all one truly wants. Does its medium matter?
            </p>
            <p>
              To answer this, both editions (physical and digital) will be
              identical yet entirely unique. Same creator, same job application,
              same aesthetic, same opening price, same piece of history. The
              only difference - their medium. This allows both markets to go
              head-to-head and battle the fundamental question, which one?
            </p>
            <p>
              This isn’t just a Steve Jobs NFT - unique, historic and wonderful
              as it is. This is a broader question to the market, which
              direction are we, as an ever digitalised society, heading towards.
            </p>
            <p>
              This marks a change in psychology. This is a notepad vs a laptop
              (a scroll vs an iPhone even). It’s the past vs. the future. This
              is not Beeple vs Picasso, that is a question of taste - and is
              ultimately subjective. This is Steve Jobs vs Steve Jobs.
            </p>
            <p>
              This is the space’s favourite debate:
              <strong>this is gold vs bitcoin</strong>. What would
              <span class="italic">Steven Jobs</span> choose?
            </p>
          </div>
        </transition>
      </div>
    </div>
  </div>
  <div class="testimonial">
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <p>
            ‘This unique auction format will truly test where value lies. Coming
            from a tech background, obviously I'm fighting on the NFT side. May
            the best Jobs application win!’
          </p>
          <p class="author">Alexander Salnikov, Co-Founder of Rarible</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container timeline">
    <div class="row">
      <div class="col text-center">
        <h2>Timeline</h2>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <div class="timeline__wrapper">
          <div class="timeline__item">
            <h3>1973</h3>
            <p>
              Steve Jobs drops-out of Reed College, Portland and pens the job
              application
            </p>
          </div>
          <div class="timeline__item">
            <h3>1974</h3>
            <p>
              Steve Jobs joins <span class="italic">Atari</span> as a technician
              and meets Steve Wozniak
            </p>
          </div>
          <div class="timeline__item">
            <h3>1976</h3>
            <p>
              <span class="italic">Apple Computers</span> founded by Steve Jobs
              and Steve Wozniak
            </p>
          </div>
          <div class="timeline__item">
            <h3>1977</h3>
            <p>
              <span class="italic">Apple II</span> released -
              <span class="italic">Apple's</span> first successful personal
              computer
            </p>
          </div>
          <div class="timeline__item">
            <h3>1980</h3>
            <p>
              <span class="italic">Apple</span> goes public, making Steve Jobs’
              net worth around $200,000,000
            </p>
          </div>
          <div class="timeline__item">
            <h3>1984</h3>
            <p>
              Steve Jobs introduces the
              <span class="italic">Macintosh</span> which becomes the first
              commercially successful small computer with a graphical user
              interface
            </p>
          </div>
          <div class="timeline__item">
            <h3>1985</h3>
            <p>
              Steve Jobs resigns as <span class="italic">Apple</span> chairman
              and starts <span class="italic">NeXT Computer</span>
            </p>
          </div>
          <div class="timeline__item">
            <h3>1985</h3>
            <p>
              A <span class="italic">NeXT</span> workstation is used by Tim
              Berners-Lee at CERN to become the first server of the World Wide
              Web
            </p>
          </div>
          <div class="timeline__item">
            <h3>1986</h3>
            <p>
              Steve Jobs acquires the Graphics Group division of
              <span class="italic">Lucasfilm</span>, that becomes
              <span class="italic">Pixar</span>
            </p>
          </div>
          <div class="timeline__item">
            <h3>1996</h3>
            <p>
              Steve Jobs returns to <span class="italic">Apple</span>, after it
              buys <span class="italic">NeXT</span> for $429 million
            </p>
          </div>
          <div class="timeline__item">
            <h3>1998</h3>
            <p>Steve Jobs introduces the <span class="italic">iMac G3</span></p>
          </div>
          <div class="timeline__item">
            <h3>2001</h3>
            <p>
              Steve Jobs introduces <span class="italic">iTunes</span> and the
              <span class="italic">iPod</span>
            </p>
          </div>
          <div class="timeline__item">
            <h3>2006</h3>
            <p>
              Disney buys <span class="italic">Pixar</span> for $7.4bn with
              Steve Jobs’ joining the board.
              <span class="italic">iTunes</span> sells its billionth song
            </p>
          </div>
          <div class="timeline__item">
            <h3>2007</h3>
            <p>Steve Jobs introduces the <span class="italic">iPhone</span></p>
          </div>
          <div class="timeline__item">
            <h3>2010</h3>
            <p>Steve Jobs introduces the <span class="italic">iPad</span></p>
          </div>
          <div class="timeline__item">
            <h3>2017</h3>
            <p>
              <span class="italic">Steve Jobs’ Job Application</span> sells at
              auction in New York, USA for $18,750
            </p>
          </div>
          <div class="timeline__item">
            <h3>2018</h3>
            <p>
              <span class="italic">Steve Jobs’ Job Application</span> sells at
              auction in Boston, USA for $174,757
            </p>
          </div>
          <div class="timeline__item">
            <h3>2021</h3>
            <p>
              <span class="italic">Steve Jobs’ Job Application</span> sells at
              auction in London, UK for $224,750
            </p>
          </div>
          <div class="timeline__item">
            <h3>2021</h3>
            <p>
              <span class="italic">Steve Jobs’ Job Application</span> digital
              counterpart is minted on the Ethereum blockchain. Bringing a
              visionary’s legacy up-to-date
            </p>
          </div>
          <div class="timeline__item">
            <h3>2021</h3>
            <p>World’s first physical vs digital head-to-head auction begins</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="year">
    <div class="container">
      <div class="row">
        <div class="col offset-sm-1">
          <h3 class="tl">1973 : The Year</h3>
          <ul>
            <li><span>First ever cell-phone call</span></li>
            <li><span>Cell-phone patent registered</span></li>
            <li><span>Robert Metcalfe invents Ethernet</span></li>
            <li><span>US dollar loses 10% of value</span></li>
            <li><span>Inflation hits 8.4%</span></li>
          </ul>
        </div>
        <div class="col-sm-3 col-12">
          <p>
            What was happening in the world the year Steve Jobs wrote the
            application?
          </p>
          <p>
            Steve Jobs, the man with no phone - who put the device we hold in
            our hands today, wrote the application the same year the first
            cell-phone call was made.
          </p>
          <p>
            We have key similarities between 1973 and 2021 with Inflation
            concerns. The FED printing money with 35% of all dollars in
            existence printed this past 12 months.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container pledge">
    <div class="row">
      <div class="col text-center">
        <h2>Pledge</h2>
        <p>
          A percentage of final profits will be donated to the following
          charities
        </p>
        <a href="https://www.cancerresearch.org/" target="_blank"
          ><img
            src="static/logos/CRI_logo_RGB_without_tagline_register_mark_hi_res.png"
            alt="Cancer Research Institute"
        /></a>
        <a href="https://www.onelaptopperchild.org/" target="_blank"
          ><img
            src="static/logos/OLPC-Logo_AF_Black.png"
            alt="One Laptop Per Child"
        /></a>
      </div>
    </div>
  </div>
  <footer-block />
  <transition name="fade">
    <modal-window v-if="showShareModal" @close="showShareModal = false">
      <template #content>
        <h2>Share</h2>
        <div class="share__links">
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fstevejobsjobapplication.com"
            target="_blank"
            class="share__links__item"
          >
            <span class="icon-facebook-f"></span>
            Facebook
          </a>
          <a
            href="https://twitter.com/intent/tweet?text=Steve+Jobs%27+Job+Application+-+The+world%E2%80%99s+first+physical+vs+NFT+auction+https%3A%2F%2Fstevejobsjobapplication.com+ends+on+Wednesday+28th+July+14.00+PDT+%23sjjanft+%23Crypto+%23NFT+%23nftcollector+%23NFTCommunity+%23NFTs+%23cryptocurrency+%23cryptoart
"
            target="_blank"
            class="share__links__item"
          >
            <span class="icon-twitter"></span>
            Twitter
          </a>
          <a
            href="mailto:?subject=Steve+Jobs%27+Job+Application+auction&body=Checkout+the+Steve+Jobs%27+Job+Application+auction+at+https%3A%2F%2Fstevejobsjobapplication.com"
            target="_blank"
            class="share__links__item"
          >
            <span class="icon-envelope-o"></span>
            Email
          </a>
          <a
            href="whatsapp://send?text=Checkout+the+Steve+Jobs%27+Job+Application+auction+at+https%3A%2F%2Fstevejobsjobapplication.com"
            target="_blank"
            class="share__links__item d-sm-none"
          >
            <span class="icon-whatsapp"></span>
            WhatsApp
          </a>
          <a
            href="https://www.reddit.com/submit?url=https%3A%2F%2Fstevejobsjobapplication.com"
            target="_blank"
            class="share__links__item"
          >
            <span class="icon-reddit-alien"></span>
            Reddit
          </a>
        </div>
        <div class="share__input">
          <input
            ref="urlInput"
            type="text"
            value="https://bit.ly/stevejobsja"
            @input="$refs.urlInput.value = 'https://bit.ly/stevejobsja'"
            @click="$refs.urlInput.select()"
          />
          <div
            :class="['share__input__copy', { green: shareCopied }]"
            @click="copyUrl"
          >
            <span class="icon-copy"></span>
          </div>
        </div>
      </template>
    </modal-window>
  </transition>
  <transition name="fade">
    <zoom-window
      v-if="showZoomModal"
      @close="showZoomModal = false"
    ></zoom-window>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import AuctionDetail from "@/components/AuctionDetail.vue";
import EndingTime from "@/mixins/EndingTime";
import VueScrollTo from "vue-scrollto";
import ModalWindow from "@/components/ModalWindow.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import ZoomWindow from "@/components/ZoomWindow.vue";
import AssetsPoll from "@/components/AssetsPoll.vue";

export default defineComponent({
  name: "Home",
  directives: {
    scrollTo: VueScrollTo,
  },
  components: {
    AssetsPoll,
    FooterBlock,
    AuctionDetail,
    ModalWindow,
    ZoomWindow,
  },
  mixins: [EndingTime],
  data() {
    return {
      showCountdown: false,
      isScrolled: false,
      showInformation: false,
      showMenu: false,
      showShareModal: false,
      showZoomModal: false,
      shareCopied: false,
    };
  },
  computed: {
    ...mapState(["lotPrices", "currencyPrices"]),
    priceRatio: function () {
      const digitalPrice = Math.max(
        1,
        this.lotPrices.digital.priceETH * this.currencyPrices.ethPrice
      );
      const physicalPrice = Math.max(1, this.lotPrices.physical?.priceUSD);
      return Math.floor(
        (1 - digitalPrice / (physicalPrice + digitalPrice)) * 100
      );
    },
  },
  watch: {
    showShareModal() {
      this.shareCopied = false;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.checkScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.checkScroll);
  },
  methods: {
    checkScroll() {
      const scrollTop = document.documentElement.scrollTop;
      this.isScrolled = scrollTop > 0;
      if (this.totalEndTime > 0) {
        this.showCountdown =
          scrollTop > (this.$refs.countdown as HTMLElement).offsetTop;
      } else {
        this.showCountdown = false;
      }
    },
    copyUrl() {
      (this.$refs.urlInput as HTMLInputElement).select();
      (this.$refs.urlInput as HTMLInputElement).setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.shareCopied = true;
    },
  },
});
</script>

<style scoped lang="scss">
.header {
  background: $colorWhite;
  position: fixed;
  height: 4rem;
  width: 100%;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  padding: 0 0.5rem;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);

  @include media-breakpoint-up(sm) {
    padding: 0 2rem;
  }

  &.shadow {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }

  &.countdown {
    top: 0;
  }

  &__title {
    font-size: 2rem;
    line-height: 4rem;
    text-align: center;
    @include condensed;
  }

  &__share {
    transform-origin: right top;
    font-size: 2rem;
    line-height: 4rem;
    font-family: $fontTitle;
    color: $colorBlack !important;
    text-decoration: none !important;
  }

  &__countdown {
    background: $colorBlack;
    height: 2rem;
    margin: 0 -2rem;

    &.ending {
      background: $colorRed;
    }

    p {
      font-size: 1rem;
      color: $colorWhite;
      font-family: $fontText;
      line-height: 2rem;
      font-weight: 500;
      text-align: center;
    }
  }
}

.nav {
  position: absolute;
  top: 100%;
  height: 900px;
  left: 0;
  background: $colorWhite;
  padding: 0.75rem 8rem 0.75rem 0.5rem;
  border-right: 1px solid $colorBlack;
  z-index: 0;

  &:before {
    display: block;
    content: "";
    position: absolute;
    right: 100%;
    width: 100%;
    background: $colorWhite;
    top: 0;
    height: 100%;
  }

  @include media-breakpoint-up(sm) {
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    background: $colorWhite;
    width: 12rem;
    padding: 0.75rem 0.5rem;
    border: 1px solid $colorBlack;
    border-top: 0;
    height: auto;

    &:before {
      display: none;
    }
  }

  &__item {
    display: block;
    font-size: 2.5rem;
    line-height: 3rem;
    transform-origin: left top;
    @include condensed;

    @include media-breakpoint-up(sm) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    background-repeat: no-repeat;
    background-image: linear-gradient($colorBlack 0.2rem, transparent 0),
      linear-gradient($colorBlack 4rem, transparent 0);
    background-size: 4rem 0.2rem, 0.2rem 4rem;
    background-position: 50% 50%, 50% 50%;
    transform: rotateZ(45deg);
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &__toggle {
    font-size: 2rem;
    line-height: 4rem;
    font-family: $fontTitle;
    color: $colorBlack !important;
    text-decoration: none !important;
    transform-origin: left top;
    text-indent: -9999px;
    width: 2rem;
    background-repeat: no-repeat;
    background-image: linear-gradient($colorBlack 0.6rem, transparent 0),
      linear-gradient($colorBlack 0.6rem, transparent 0),
      linear-gradient($colorBlack 0.6rem, transparent 0);
    background-size: 2rem 0.3rem, 2rem 0.3rem, 2rem 0.3rem;
    background-position: 0 1rem, 0 1.7rem, 0 2.4rem;

    @include media-breakpoint-up(sm) {
      text-indent: 0;
      width: auto;
      background: none;
    }
  }
}

.transmenu-enter-active,
.transmenu-leave-active {
  transition: all 0.3s ease-in-out;
}

.transmenu-enter-from,
.transmenu-leave-to {
  left: -40rem;
  @include media-breakpoint-up(sm) {
    left: 0;
    opacity: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.claim {
  margin: 12.5rem 0 6.25rem;
  text-align: center;

  h2 {
    font-size: 6rem;
    line-height: 5rem;
    margin-bottom: 3rem;
    @include condensed;
    @include media-breakpoint-up(sm) {
      font-size: 9rem;
      line-height: 8rem;
      margin-bottom: 5rem;
    }
  }

  h3 {
    font-size: 2em;
    margin-bottom: 1rem;
    @include condensed;
    @include media-breakpoint-up(sm) {
      font-size: 3rem;
      margin-bottom: 1.5rem;
    }
  }

  &__countdown {
    font-size: 2rem;
    font-family: $fontTitle;
    font-weight: 300;
    margin-bottom: 3rem;
    @include media-breakpoint-up(sm) {
      font-size: 3rem;
      margin-bottom: 5rem;
    }

    .timer {
      display: flex;
      justify-content: center;

      &.ending {
        .condensed {
          color: $colorRed;
        }
      }

      p {
        font-size: 5rem;
        line-height: 4rem;
        margin: 0 0.5rem;
        @include media-breakpoint-up(sm) {
          font-size: 8rem;
        }

        .desc {
          display: block;
          text-align: center;
          font-size: 1rem;
          line-height: 1rem;
          margin-top: 1.25rem;
          font-family: $fontText;
          letter-spacing: 0;
        }
      }

      &__info {
        p {
          font-family: $fontText;
          font-size: 1rem;
          max-width: 30rem;
          margin: 3rem auto 0;
        }
      }
    }
  }
}

.auction {
  h2 {
    font-size: 5rem;
    line-height: 5rem;
    text-align: center;
    margin-bottom: 2rem;
    @include condensed;

    @include media-breakpoint-up(sm) {
      font-size: 7rem;
      line-height: 7rem;
    }
  }

  &__logo {
    margin-bottom: 5rem;
    span {
      font-size: 1.7rem;
      line-height: 4rem;
      @include condensed;
    }

    img {
      margin-top: 0.5rem;
      width: 100%;
      max-width: 11.25rem;
    }
  }

  &__image {
    position: relative;

    img {
      cursor: zoom-in;
    }

    .sold {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        font-size: 5.25rem;
        color: $colorWhite;
        margin: 0;
        text-align: center;
        @include condensed;
      }
    }
  }
}

.progress {
  &__wrapper {
    padding: 2rem 0.5rem;
    margin-top: 0.6rem;
    margin-bottom: 8rem;
    border: 1px solid $colorBlack;
    @include media-breakpoint-up(sm) {
      padding: 2rem 4.5rem;
    }

    h3 {
      font-size: 2rem;
    }

    h4 {
      font-size: 2rem;
    }
  }

  &__bar {
    border: 1px solid $colorBlack;
    padding: 3px;
    display: flex;

    div {
      padding: 0.25rem 1rem;
      font-size: 0.9rem;
      line-height: 1.4rem;
      color: $colorWhite;
      flex-basis: 0;
      max-width: 100%;
    }

    &__physical {
      background: $colorBlack;
      transition: all 0.3s ease-in-out;
    }

    &__digital {
      background: $colorGrey;
      flex-grow: 1;
    }
  }
}

.authenticate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
  &__logo {
    display: block;
    margin: 0.6rem;
    font-size: 0.8rem;
  }
  &__image {
    display: flex;
    padding: 0.6rem;
    width: 8.5rem;
    border: 1px solid $colorBlack;
    border-radius: 5px;
    height: 4.5rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.3rem;
    img {
      max-height: 3.1rem;
      max-width: 4.3rem;
    }
  }
}

.market {
  background: $colorBlack;
  padding: 1rem 0;

  .col-12 {
    text-align: center;
    @include media-breakpoint-up(sm) {
      text-align: right;
    }
    &:first-child {
      text-align: center;
      @include media-breakpoint-up(sm) {
        text-align: left;
        border-right: 1px solid $colorWhite;
      }
    }
  }

  p {
    font-size: 2rem;
    line-height: 2.5rem;
    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
      line-height: 6.75rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }
    text-transform: uppercase;
    color: $colorWhite;
    margin: 0;
  }
}

.manifesto {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;

  h2 {
    margin-bottom: 4.3rem;
  }

  p {
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 2rem;
    @include condensed;
    @include media-breakpoint-up(sm) {
      font-size: 4rem;
      line-height: 4rem;
      margin-bottom: 4rem;
    }
  }
}

.press {
  background: $colorBlack;
  padding: 3.1rem 0;

  h2 {
    color: $colorWhite;
    margin-bottom: 2.5rem;
  }

  a {
    display: inline-block;
    text-align: center;
    width: 50%;
    height: 4rem;
    box-sizing: border-box;

    @include media-breakpoint-up(sm) {
      margin: 0.5rem 0;
      width: auto;
    }
    @include media-breakpoint-up(lg) {
      margin: 0 1rem;
    }
    img {
      width: 80%;
      max-width: 9rem;
      max-height: 3.1rem;
      @include media-breakpoint-up(sm) {
      }
      @include media-breakpoint-up(lg) {
        max-width: 12rem;
        width: auto;
      }
    }
  }
}

.piece {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;

  h2 {
    margin-bottom: 4.3rem;
  }

  p {
    font-size: 3rem;
    line-height: 3rem;
    margin: 0 auto 3rem;
    max-width: 61rem;
    @include condensed;
    @include media-breakpoint-up(sm) {
      font-size: 4rem;
      line-height: 4rem;
      margin-bottom: 4rem;
    }
  }

  &__information {
    padding-top: 4rem;
    overflow: hidden;
    max-height: 76.25rem;
    @include media-breakpoint-up(sm) {
      column-count: 2;
      column-gap: 5rem;
      max-width: 61rem;
      margin: 0 auto;
      max-height: 56.25rem;
    }
    p {
      font-size: 1.6rem;
      line-height: 1.8rem;
      margin-bottom: 1rem;
      text-align: left;
      @include media-breakpoint-up(sm) {
        font-size: 1.4rem;
        line-height: 1.5rem;
      }
    }
  }
}

.information-enter-active,
.information-leave-active {
  transition: all 0.7s ease-in-out;
}

.information-enter-from,
.information-leave-to {
  max-height: 0;
  padding-top: 0;
  opacity: 0;
}

.testimonial {
  background: $colorBlack;
  padding: 6.25rem 0;

  p {
    color: $colorWhite;
    margin: 0 auto 2rem;
    font-size: 2.8rem;
    line-height: 2.55rem;
    max-width: 28rem;
    @include condensed;

    &.author {
      font-size: 1.75rem;
      line-height: 1.75rem;
      margin-bottom: 0;
    }
  }
}

.timeline {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;

  h2 {
    font-size: 5rem;
    line-height: 5rem;
    margin-bottom: 4.3rem;
    @include media-breakpoint-up(sm) {
      font-size: 7rem;
      line-height: 7rem;
    }
  }

  &__wrapper {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    @include media-breakpoint-up(sm) {
      &:before {
        position: absolute;
        display: block;
        top: 0;
        left: 50%;
        height: 100%;
        width: 1px;
        content: "";
        background: $colorBlack;
      }
    }
  }

  &__item {
    max-width: 100%;
    flex: 1 1 100%;
    padding: 1rem;
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      max-width: 50%;
      flex: 1 1 50%;
      padding: 3.1rem;

      &:nth-child(2) {
        margin-top: 9.34rem;
      }

      &:nth-child(2n + 3) {
        margin-top: -9.34rem;
      }

      &:nth-child(2n) {
        h3 {
          &:before {
            left: unset;
            right: 80%;
          }
        }
      }
    }

    h3 {
      font-size: 5.5rem;
      line-height: 5.5rem;
      margin-bottom: 0.5rem;
      position: relative;
      @include media-breakpoint-up(sm) {
        margin-bottom: 1rem;
        font-size: 7.5rem;
        line-height: 7.5rem;
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 80%;
          width: 60%;
          height: 1px;
          background: $colorBlack;
        }
      }
    }

    p {
      font-size: 1.1rem;
      margin: 0;
    }
  }
}

.year {
  background: $colorBlack;
  padding: 6.25rem 0;

  .col {
    order: unset;
    @include media-breakpoint-up(sm) {
      order: 2;
    }
  }

  h3 {
    font-size: 4rem;
    line-height: 4rem;
    color: $colorWhite;
    margin-bottom: 5rem;
    @include media-breakpoint-up(sm) {
      font-size: 4.5rem;
      line-height: 4.5rem;
    }
  }

  p {
    font-size: 1.2rem;
    color: $colorWhite;
    margin-bottom: 2.5rem;
    @include media-breakpoint-up(sm) {
      font-size: 0.9rem;
    }

    &:first-of-type {
      @include media-breakpoint-up(sm) {
        margin-top: 1.5rem;
        margin-bottom: 6rem;
      }
    }
  }

  ul {
    color: $colorWhite;
    padding: 0;
    list-style-type: none;
    margin-bottom: 2rem;
    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }

    li {
      font-size: 4rem;
      line-height: 4rem;
      position: relative;
      padding-left: 1.8rem;
      @include media-breakpoint-up(sm) {
        font-size: 4.5rem;
        line-height: 4.5rem;
      }

      span {
        display: inline-block;
        transform-origin: top left;
        @include condensed;
      }

      &:before {
        position: absolute;
        content: "";
        display: block;
        top: 1.8rem;
        left: 0;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: $colorWhite;
        @include media-breakpoint-up(sm) {
          top: 2.1rem;
        }
      }
    }
  }
}

.pledge {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;

  h2 {
    margin-bottom: 2.5rem;
  }
  p {
    font-size: 4rem;
    line-height: 4rem;
    max-width: 61rem;
    margin: 0 auto 4rem;
    @include condensed;
    @include media-breakpoint-up(sm) {
      font-size: 7rem;
      line-height: 7rem;
    }
  }
  a {
    display: inline-block;
    margin: 1rem 2rem;
    @include media-breakpoint-up(sm) {
      margin: 0 2rem;
    }

    img {
      max-width: 9.4rem;
      max-height: 5.9rem;
    }
  }
}

.share {
  &__links {
    display: flex;
    justify-content: center;
    &__item {
      display: block;
      margin: 0;
      text-decoration: none !important;
      text-align: center;
      color: $colorWhite !important;
      font-size: 0.7rem;
      width: 4rem;

      span {
        color: $colorBlack;
        background: $colorWhite;
        display: block;
        width: 2.6rem;
        height: 2.6rem;
        line-height: 2.7rem;
        border-radius: 0.3rem;
        font-size: 1.8rem;
        margin: 0 auto 0.5rem;
      }
    }
  }
  &__input {
    display: flex;
    margin-top: 2rem;
    width: 24rem;
    @include media-breakpoint-up(md) {
      margin: 2rem 0 0;
    }
    input {
      margin: 0;
      text-align: left;
      border: 0;
      width: 100%;
      padding: 0 0.5rem;
    }

    &__copy {
      background: $colorWhite;
      color: $colorBlack;
      line-height: 32px;
      height: 32px;
      padding: 0 0.5rem;
      font-size: 12px;
      cursor: pointer;
      @include media-breakpoint-up(md) {
        line-height: 3.5rem;
        height: 3.5rem;
        font-size: 16px;
      }

      &.green {
        color: #00aa00;
      }
    }
  }
}
</style>
