<template>
  <div class="container page">
    <div class="row">
      <div class="col text-center">
        <h1>Sorry, that shouldn't happend</h1>
        <router-link :to="{ name: 'Home' }" class="button primary"
          ><span>Back to Homepage</span></router-link
        >
        <img
          src="teared.jpg"
          class="teared"
          alt="Steve Jobs' Job Application"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorPage",
});
</script>

<style scoped lang="scss">
.button {
  display: block;
  margin: 0 auto 3rem;
  max-width: 12rem;
}
.teared {
  width: 90%;
  max-width: 400px;
}
</style>
