import { createStore } from "vuex";
import { State } from "@/types/state";

const state: State = {
  isLoggedIn: false,
  userLevel: 0,
  lotPrices: {
    digital: {
      priceUSD: 0,
      priceETH: 12,
      endTimestamp: { seconds: 0 },
      bidsNumber: 10,
      reserveMet: false,
    },
    physical: {
      priceUSD: 343000,
      priceETH: 0,
      endTimestamp: { seconds: 0 },
      bidsNumber: 43,
      reserveMet: false,
    },
  },
  localDate: new Date(),
  localDrift: 0,
  currencyPrices: {
    goldPrice: 0,
    bitcoinPrice: 0,
    ethPrice: 2288.35,
  },
  userData: {
    firstname: "",
    lastname: "",
    email: "",
    phonePrefix: "+1",
    phone: "",
    mailingPreference: false,
    userLevel: 0,
    billingAddress: {
      firstLine: "",
      city: "",
      postcode: "",
      country: "",
      state: "",
    },
  },
  poll: {
    digital: 194,
    physical: 764,
  },
  timeOffset: 0,
};

export default createStore<State>({
  state,
  mutations: {},
  actions: {},
  modules: {},
  getters: {
    digitalEndTime: () => {
      return 0;
    },
    physicalEndTime: () => {
      return 0;
    },
    totalEndTime: () => {
      return 0;
    },
  },
});
