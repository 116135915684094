
import { defineComponent } from "vue";

export default defineComponent({
  name: "CookieConsent",
  data() {
    return {
      showConsent: true,
    };
  },
  computed: {
    firstVisit(): boolean {
      return !this.$cookies.isKey("sjja-cookie-consent");
    },
  },
  methods: {
    setCookie() {
      this.$cookies.set("sjja-cookie-consent", "1");
      this.showConsent = false;
    },
  },
});
