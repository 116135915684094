<template>
  <div class="full-page">
    <header class="header">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-3">
            <router-link :to="{ name: 'Lot' }" class="header__logo">
              <img src="static/logos/SnoofaMock.png" alt="Snoofa" />
            </router-link>
          </div>
          <div class="co-12 col-sm d-flex fle">
            <nav class="nav">
              <router-link :to="{ name: 'Home' }">
                <span>Home</span>
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <div class="full-page__content">
      <router-view></router-view>
    </div>
    <footer-block />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FooterBlock from "@/components/FooterBlock.vue";

export default defineComponent({
  name: "Snoofa",
  components: {
    FooterBlock,
  },
});
</script>

<style scoped lang="scss">
.full-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    flex: 1;
  }
}

.header {
  height: auto;
  background: $colorWhite;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  @include media-breakpoint-up(sm) {
    text-align: left;
    min-height: 5.75rem;
  }

  &.ended {
    min-height: unset;
    height: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    @include media-breakpoint-up(sm) {
      height: 5.75rem;
    }
  }

  .col-sm {
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
    }
  }

  .user__info {
    border-left: 1px solid #eee;
    display: flex;
    align-items: center;
    &__circle {
      background: $colorGrey;
      font-size: 1rem;
      line-height: 1.8rem;
      width: 2rem;
      height: 2rem;
      text-align: center;
      display: block;
      border-radius: 50%;
      font-weight: 700;
      color: $colorWhite;
      margin-left: 0.5rem;
    }
    &__name {
      margin-left: 0.5rem;
      p {
        margin: 0;
        font-size: 0.8rem;
        line-height: 1rem;

        &.small {
          font-size: 0.6rem;
        }
      }
    }
    .button {
      margin-left: 0.5rem;
    }
  }

  .back {
    font-family: $fontTitle;
    font-size: 1.25rem;
    line-height: 2rem;
    margin: 0.5rem 0;
    display: inline-block;
  }

  &__logo {
    display: flex;
    height: 4rem;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(sm) {
      justify-content: unset;
      height: 100%;
    }
    img {
      height: 2.25rem;
      margin-top: -0.5rem;
    }
  }

  &__countdown {
    background: $colorBlack;
    height: 2rem;
    margin: 0;

    &.ending {
      background: $colorRed;
    }

    p {
      font-size: 1.2rem;
      color: $colorWhite;
      font-family: $fontText;
      line-height: 2rem;
      font-weight: 500;
      text-align: center;
    }
  }
}

.nav {
  text-align: center;
  @include media-breakpoint-up(sm) {
    text-align: right;
  }
  a:not(.button) {
    display: inline-block;
    line-height: 5.6rem;
    padding: 0 0.5rem;
    font-size: 1.25rem;
    transition: all 0.2s ease-in-out;
    text-decoration: none !important;
    @include media-breakpoint-up(sm) {
      padding: 0 1rem;
    }

    span {
      display: inline-block;
      @include condensed;
    }

    &:hover {
      background: #f2f2f2;
    }

    &.nav__social {
      span {
        font-size: 1.5rem;
        vertical-align: middle;
      }
    }
  }

  .button {
    margin: 0 0.5rem;
    @include media-breakpoint-up(sm) {
      margin: 0 1rem;
    }
  }
}
</style>
