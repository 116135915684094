
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "AssetsPoll",
  data() {
    return {
      voted: false,
    };
  },
  computed: {
    ...mapState(["poll"]),
    physicalPerc(): number {
      if (this.poll.physical === 0) {
        return 0;
      }
      return parseInt(
        (
          (this.poll.physical / (this.poll.digital + this.poll.physical)) *
          100
        ).toFixed(0)
      );
    },
    digitalPerc(): number {
      if (this.poll.digital === 0) {
        return 0;
      }
      return 100 - this.physicalPerc;
    },
  },
});
