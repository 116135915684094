<template>
  <header class="page__header">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <router-link
            :to="{ name: 'Home', hash: '#footer' }"
            class="page__header__link condensed"
            >Back</router-link
          >
        </div>
      </div>
    </div>
  </header>
  <div class="container page">
    <div class="row">
      <div class="col text-center">
        <h1>FAQ</h1>
        <h2>General Auction Questions</h2>
        <p class="question">
          How many times has the physical item been sold at auction?
        </p>
        <p>
          The physical item first went up for auction in 2017 with Bonhams in
          New York. Since then it has gone through auction 2 more times
          increasing 1,200% in value over the 4 year period.
        </p>
        <p class="question">
          Why are you selling the physical application and the NFT application
          separately?
        </p>
        <p>
          Non-fungible tokens have dominated the art and collectibles sphere for
          months, but there is a debate around whether they represent real
          value, with doubts on whether they will stand the test of time against
          traditional physical assets. By putting the NFT and original physical
          job application up for auction simultaneously, the sale will test the
          appetite for digital assets in contrast with physical equivalents.
          This first-of-its-kind auction will challenge the notion of value in
          the physical and digital world. The result of the auction will show if
          true value can be embodied in both or whether one medium is leading
          the way.
        </p>
        <p class="question">
          Will the digital (NFT) and physical version of Steve Jobs’ job
          application be identical?
        </p>
        <p>
          The auction will feature two identical versions of Jobs’ infamous
          first job application, yet both are entirely unique - with the same
          origin, same detail, same aesthetic, same opening price, the same
          piece of history - the only difference is their medium.
        </p>
        <p class="question">
          Is there a maximum you can bid on the physical and digital (NFT) Steve
          Jobs’ job application?
        </p>
        <p>
          No, there is no maximum bid amount on either physical or NFT auction.
        </p>
        <p class="question">
          Why does the auction start at such a specific time of 9:41am PDT?
        </p>
        <p>
          This is the exact timestamp that Steve Jobs would aim to reveal the
          ‘headline’ news during his iconic Apple press conferences. It is
          paying homage to the immaculate attention to detail of the great man
          himself. When launching the first iPhone, Steve Jobs wanted the grand
          reveal of the hero product launch to have the same time on the screen
          as the actual time it was launched, so members in the audience would
          see the correct time. After practicing his pitch (that started at
          9:00am) he noted down that it lasted approximately 40 minutes. He left
          a 1 minute buffer for slight delays. This plan worked and the product
          launch happened at 09:41am.
        </p>
        <p class="question">Can I bid on both auctions?</p>
        <p>
          Absolutely, if you want to bid on both items that is fine. As they are
          2 separate auctions, with 2 separate auction providers. You must
          ensure you have accounts with both in order to successfully place
          bids.
        </p>
        <p class="question">
          Are ‘Anti-Sniping’ rules in place on the auctions?
        </p>
        <p>
          Yes, both auctions have ‘Anti-Sniping’ rules in place. This means if a
          bid is received in the last 10 minutes of an auction, that auction
          will extend by a further 10 minutes until there are no more bids
          placed in the final 10 minutes. This is the case on both physical and
          digital (NFT) auctions.
        </p>
        <p class="question">
          How do you know it is actually Steve Jobs’ Job Application?
        </p>
        <p>
          The Steve Jobs Job Application (1973) has been verified as authentic
          by 2 independent collectible authentication services, PSA DNA and
          Beckett. Links to both of these services (alongside the certification
          numbers) are on the main landing page beneath the auctions. This item
          has also already been through 3 prior auctions, starting at Bonhams
          (New York) in 2017.
        </p>
        <p class="question">Which countries do you accept bids from?</p>
        <p>Both auctions are open to a global audience.</p>
        <p class="question">I have a press enquiry, who can I speak to?</p>
        <p>
          For all press enquiries please contact Emily Long :
          Emily@pangolinpr.com
        </p>
        <p class="question">I have a general enquiry, who can I speak to?</p>
        <p>
          For all general enquiries please contact
          info@stevejobsjobapplication.com
        </p>
        <p class="question">Where are the auction terms and conditions?</p>
        <p>
          Auction terms and conditions can be found
          <router-link :to="{ name: 'AuctionTerms' }">here</router-link>.
        </p>
        <p class="question">Where are the privacy and cookies policy?</p>
        <p>
          Privacy and cookies policies can be found
          <router-link :to="{ name: 'PrivacyPolicy' }">here</router-link>.
        </p>

        <h2>Physical Auction Questions</h2>

        <p class="question">
          What currencies do you accept as payment for the physical item.
        </p>
        <p>
          For the physical item we accept payment in US Dollars and the
          equivalent value in Ethereum (ETH) cryptocurrency. Note, the USD/ETH
          exchange rate fluctuates, therefore if paying in ETH it will be the
          equivalent value to the USD hammer price at time of payment not the
          amount of displayed ETH at time of hammer price.
        </p>
        <p class="question">
          What type of auction does the physical auction follow?
        </p>
        <p>
          The auction follows an English auction. This means whatever bid is
          received will be put forward as the highest bid. There is no hidden
          highest bid. If this figure is the highest bid it will be the current
          price immediately. Eg. if the price is on £100,000 and you bid
          £1,000,000 the live price would change to £1,000,000 it wouldn’t go to
          £105,000 and automatically outbid competing bids up to £1,000,000.
        </p>
        <p class="question">
          If I win the physical auction will I also win the NFT?
        </p>
        <p>
          No, they are 2 separate auctions. If you win the physical auction you
          will only receive the physical Steve Jobs’ Job Application.
        </p>
        <p class="question">
          Is there a buyer's fee on top of the hammer price for the physical
          auction?
        </p>
        <p>
          Yes, there is a 2.5% buyer’s fee applicable on the hammer price. This
          will be added to the invoice and issued to the winner after the
          auction has concluded.
        </p>
        <p class="question">
          Are there any other additional fees (on top of buyers fee) added to
          the final invoice?
        </p>
        <p>
          This entirely depends on what country the winning customer is from.
          The buyer will be responsible for covering all shipping costs. The
          item is based and being sold with a UK company. If you are a UK
          resident/entity, added VAT will be applicable. If you are outside the
          UK there may be import taxes that the buyer would need to cover.
        </p>
        <p class="question">What happens once I’ve won the physical auction?</p>
        <p>
          We will reach out to you by phone and email to arrange delivery. We’ll
          then send you the final invoice, once this is settled the item can be
          collected/delivered.
        </p>
        <p class="question">Who is Snoofa?</p>
        <p>
          Snoofa are our auction partners for the physical auction. Snoofa
          provides specialist auctioneering software whose clients include some
          of the largest regional auction houses in the UK. Unique functionality
          is blended with traditional auctioneering principles.
        </p>
        <p class="question">How do I create an account with Snoofa?</p>
        <p>
          Press ‘Place Bid’ on the physical auction. You’ll then be taken
          through to the physical auction page. From here, press ’<router-link
            :to="{ name: 'Step1' }"
            >Register</router-link
          >’ and follow the steps through to
          <router-link :to="{ name: 'Step1' }">register</router-link>.
        </p>
        <p class="question">Why are you asking me for my card details?</p>
        <p>
          In order to verify you and ensure you are a genuine bidder, we require
          a $100 holding fee. This verification process is done securely using
          Stripe.
        </p>
        <p class="question">When will I get my $100 holding fee back?</p>
        <p>
          Your $100 holding fee will be refunded at the end of the auction (28th
          July - 2:00pm PDT)
        </p>
        <p class="question">
          I’ve tried to sign up to bid on the physical auction and haven’t
          received a verification email, what should I do?
        </p>
        <p>
          Firstly, check if the email address you have entered is correct. If it
          is, then please wait a few minutes then check your spam/junk folder.
          At this point if the verification email still hasn’t arrived, please
          reach out to us on the ‘Contact us’ form (The ‘Help ?’ icon in the
          bottom right hand corner of the screen) and we’ll manually verify you.
        </p>

        <h2>Digital Auction (NFT) Questions</h2>

        <p class="question">
          What currencies do you accept as payment for the digital (NFT) item.
        </p>
        <p>
          We only accept Ethereum (ETH) as payment for the digital (NFT). This
          is converted to wrapped ETH (WETH) by Rarible for the bidding process.
          WETH and ETH have the same value, 1:1 conversion.
        </p>
        <p class="question">
          Do you accept US Dollars as payment for the digital (NFT) item.
        </p>
        <p>No, we only accept ETH/WETH.</p>
        <p class="question">
          What type of auction does the digital (NFT) auction follow?
        </p>
        <p>
          The digital (NFT) auction follows a very similar type to the physical
          English auction.
        </p>
        <p class="question">
          If I win the digital (NFT) auction will I also win the physical item?
        </p>
        <p>
          No, they are 2 separate auctions. If you win the digital (NFT) auction
          you will only receive the digital (NFT) Steve Jobs’ Job Application.
        </p>
        <p class="question">
          Is there a buyer's fee on top of the hammer price for the digital
          (NFT) auction?
        </p>
        <p>
          Yes, Rarible charges a 2.5% buyer’s fee applicable on the hammer
          price. This will be added to the total by Rarible.
        </p>
        <p class="question">
          Are there any other additional fees (on top of buyers fee) added to
          the final invoice?
        </p>
        <p>The only additional costs that you’ll incur are the ETH gas fees.</p>
        <p class="question">
          What happens once I’ve won the digital (NFT) auction?
        </p>
        <p>
          When the auction has finished and payment has been made the smart
          contract behind the NFT will execute and transfer ownership of NFT on
          the blockchain to the new owner. This process will happen
          automatically on Rarible’s platform and once we accept the winning
          bid. The NFT will then appear in your ‘My Items’ section.
        </p>
        <p class="question">Who is Rarible?</p>
        <p>
          Rarible are our auction partners for the digital (NFT) auction. They
          are the largest decentralized, creator-centric NFT marketplace for a
          variety of multimedia content: digital art, collectibles, music,
          video, and more. Rarible offers a user-friendly social media-like
          experience for creators and collectors, having exceeded $40 million in
          trading volume for the past month.
        </p>
        <p class="question">How do I create an account with Rarible?</p>
        <p>
          You will need to
          <a href="https://rarible.com/connect" target="_blank">connect</a> a
          crypto wallet to access Rarible. Rarible will show you how to do this
          on their
          <a href="https://rarible.com/connect" target="_blank">site</a>.
        </p>
        <p class="question">
          How was Steve Jobs’ job application turned into an NFT?
        </p>
        <p>
          The Steve Jobs’ job application NFT was created in partnership with
          Rarible - a leading NFT marketplace allowing digital artists and
          creators to issue and sell custom crypto assets. Rarible hosts the
          digital image behind the NFT on IPFS protocol (decentralised hosting)
          to ensure the digital file is never lost in transfer of ownership.
        </p>

        <h2>Charity Partners</h2>
        <p class="question">
          Will a percentage of the profit be going to charity?
        </p>
        <p>
          Yes a percentage of profits from the auction will be donated to a
          selection of charities that closely link to Jobs’ previous charitable
          endeavors, including the Cancer Research Institute and One Laptop Per
          Child.
        </p>
        <p class="question">
          Who are the select charity partners for the auction?
        </p>
        <p>
          The charity partners who will be receiving a percentage of the auction
          proceeds will be the Cancer Research Institute and One Laptop Per
          Child.
        </p>
        <p class="question">What is the Cancer Research Institute?</p>
        <p>
          The Cancer Research Institute is a nonprofit organization dedicated to
          harnessing our immune system's power to control and potentially cure
          all types of cancer. They fund the most innovative clinical and
          laboratory research around the world, support the next generation of
          the field's leaders, and serve as a trusted source of information on
          immunotherapy for cancer patients and their caregivers.
        </p>
        <p class="question">What is the One Laptop Per Child?</p>
        <p>
          One Laptop per Child (OPLC) is a non-profit initiative with the goal
          of transforming education for children around the world. OLPC aims to
          create and distribute educational devices for the developing world and
          create software and content for those devices.
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Faq",
  created() {
    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.goBack();
      }
    });
  },
  unmounted() {
    document.removeEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.goBack();
      }
    });
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
});
</script>

<style scoped lang="scss">
p {
  text-align: left;

  &.question {
    font-weight: bold;
  }
}
</style>
