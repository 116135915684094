<template>
  <div
    :class="[
      'lot',
      { 'with-message': isWinning || isOutbid || $store.state.currentError },
    ]"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 col-10 order-md-0 order-2 hide-xs">
          <div class="lot__image">
            <div
              v-if="physicalEndTime <= 0 && lotPrices.physical.reserveMet"
              class="sold"
            >
              <p>Lot Finished</p>
            </div>
            <div
              v-else-if="physicalEndTime <= 0 && !lotPrices.physical.reserveMet"
              class="sold"
            >
              <p>Lot Finished</p>
            </div>
            <img
              src="application.jpg"
              alt="Steve Jobs Job Application"
              @click="showZoomModal = true"
              @click.right.prevent
            />
          </div>
        </div>
        <div class="col-md-6 col-10 lot__left">
          <div class="lot__desc">
            <h1 class="tl">Steve Jobs’ Job Application</h1>
            <h2 class="tl">Year: 1973</h2>
            <h2 class="tl">Physical | Edition 1 of 1</h2>
            <p>Item Location: London, UK</p>
          </div>
          <div class="lot__image show-xs">
            <div
              class="sold"
            >
              <p>Lot Finished</p>
            </div>
            <img
              src="static/application.jpg"
              alt="Steve Jobs Job Application"
              @click="showZoomModal = true"
              @click.right.prevent
            />
          </div>
          <div class="lot__price">
            <div class="lot__price__header">
              <p v-if="physicalEndTime > 0">Current Bid (USD)</p>
              <p v-if="physicalEndTime <= 0">Last Bid (USD)</p>
              <p
                v-if="physicalEndTime > 0"
                :class="['strong', { ending: physicalEndTime <= 600 }]"
              >
                Ending in: {{ remainingTime.days }}d {{ remainingTime.hours }}h
                {{ remainingTime.minutes }}m {{ remainingTime.seconds }}s
              </p>
              <p v-else class="strong">Ended</p>
            </div>
            <div class="lot__price__content">
              <div class="price">
                <h3 class="tl bold">
                  $
                  {{
                    lotPrices.physical.priceUSD
                      .toFixed(2)
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </h3>
                <span
                  >(<span class="eth">Ξ</span
                  >{{
                    (
                      lotPrices.physical.priceUSD / currencyPrices.ethPrice
                    ).toFixed(3)
                  }})</span
                >
              </div>
              <div>
                <p class="strong">{{ lotPrices.physical.bidsNumber }} Bids</p>
                <p v-if="!lotPrices.physical.reserveMet && clientMaxBid">
                  Reserve not met
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tabs">
    <div class="container">
      <div class="row justify-content-center tabs__item">
        <div class="col-10">
          <div
            class="tabs__item__title"
            @click="tabs.information = !tabs.information"
          >
            <h2 class="tl">Description</h2>
            <div
              :class="['tabs__item__toggle', { open: tabs.information }]"
            ></div>
          </div>
          <transition name="slide">
            <div v-if="tabs.information" class="tabs__item__content">
              <p>
                This extremely rare collectable first went up for auction in
                2017 with Bonhams, New York. Since then it has gone through 2
                subsequent auctions, increasing approximately 1,200% in value
                over the 4 year period.
              </p>
              <p>
                The Steve Jobs’ Job Application from 1973 is a unique piece of
                history from the exact moment that a dreamer changed the world.
                It’s a snapshot into the mind of a future genius at a moment
                where any small deviation from the path ahead, would have meant
                a very different world today.
              </p>
              <p>
                The application dimensions are approximately 210 x 297 mm (A4
                size).
              </p>
              <p>
                It has been independently verified by both PSA DNA and Beckett
                Authentication.
              </p>
              <p>
                You can view the authenticity certificates by clicking the links
                and entering the below certification numbers:
              </p>
              <a href="https://www.psacard.com/cert/ae01173" target="_blank"
                >PSA DNA: #AE01173</a
              >
              <a
                href="https://www.beckett-authentication.com/verify-certificate"
                target="_blank"
                >Beckett Authentication: #A77006</a
              >
            </div>
          </transition>
        </div>
      </div>
      <div class="row justify-content-center tabs__item">
        <div class="col-10">
          <div class="tabs__item__title" @click="tabs.details = !tabs.details">
            <h2 class="tl">Auction Details</h2>
            <div :class="['tabs__item__toggle', { open: tabs.details }]"></div>
          </div>
          <transition name="slide">
            <div v-if="tabs.details" class="tabs__item__content">
              <dl>
                <dt>Auction start time:</dt>
                <dd>21st July 2021, 09:41 PDT</dd>
                <dt>Auction end time:</dt>
                <dd>28th July 2021, 14:00 PDT</dd>
                <dt>Item location:</dt>
                <dd>London, United Kingdom</dd>
                <dt>Auction currency:</dt>
                <dd>US dollars (USD)</dd>
                <dt>Payment accepted:</dt>
                <dd>US dollars (USD) or Ethereum (ETH) cryptocurrency</dd>
                <dt>Buyers premium:</dt>
                <dd>2.5% of hammer price</dd>
              </dl>
              <p>
                VAT (20%) will be applied to invoice if applicable / VAT(20%) as
                applicable.
              </p>
              <p>
                If a bid is received within the last 10 minutes of the auction,
                the auction will extend by a further 10 minutes until there are
                no more bids received in the last 10 minutes.
              </p>
              <p>
                A holding fee of $100 will be required from customers before
                they can bid, this will be taken via Stripe and is released at
                the end of the auction.
              </p>
              <p>If you win this lot, you will win the physical item.</p>
            </div>
          </transition>
        </div>
      </div>
      <div class="row justify-content-center tabs__item">
        <div class="col-10">
          <div
            class="tabs__item__title"
            @click="tabs.shipping = !tabs.shipping"
          >
            <h2 class="tl">Shipping</h2>
            <div :class="['tabs__item__toggle', { open: tabs.shipping }]"></div>
          </div>
          <transition name="slide">
            <div v-if="tabs.shipping" class="tabs__item__content">
              <p>
                The buyer is responsible for arranging all shipping, import and
                export and paying all associated cost, duties and taxes. The
                winning bidder will be contacted after the auction has finished
                to organise shipping arrangements. See Terms and Conditions of
                Sale for more details.
              </p>
            </div>
          </transition>
        </div>
      </div>
      <div class="row justify-content-center tabs__item">
        <div class="col-10">
          <div class="tabs__item__title" @click="tabs.terms = !tabs.terms">
            <h2 class="tl">Terms & Conditions of Sale</h2>
            <div :class="['tabs__item__toggle', { open: tabs.terms }]"></div>
          </div>
          <transition name="slide">
            <div v-if="tabs.terms" class="tabs__item__content">
              <p>
                Please make sure you have read and agree with all the auction
                terms and conditions prior to bidding on this auction as these
                set out the terms for bidding and the legally binding contract
                with you if you are the successful bidder.
              </p>
              <p>
                Full Terms and Conditions of sale can be found
                <router-link :to="{ name: 'SaleTerms' }">here</router-link>.
              </p>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <modal-window
      v-if="showConfirmModal"
      white
      @close="showConfirmModal = false"
    >
      <template #content>
        <p class="text-center">
          This is your <strong>final bid</strong> and you'll only be able to
          change it if you are outbid.<br />
          Are you sure you want to bid?
        </p>
        <div class="modal__content__price">
          $
          {{ bid.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") }}
        </div>
        <div class="modal__content__buttons">
          <button
            class="button secondary small"
            @click="showConfirmModal = false"
          >
            <span>Cancel</span>
          </button>
          <button
            class="button primary small"
            @click="
              placeBid();
              showConfirmModal = false;
            "
          >
            <span>Place bid</span>
          </button>
        </div>
      </template>
    </modal-window>
  </transition>
  <transition name="fade">
    <zoom-window
      v-if="showZoomModal"
      @close="showZoomModal = false"
    ></zoom-window>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EndingTime from "@/mixins/EndingTime";
import { Actions } from "@/types/actions";
import ModalWindow from "@/components/ModalWindow.vue";
import firebase from "firebase/app";
import { mapGetters, mapState } from "vuex";
import { Mutations } from "@/types/mutations";
import ZoomWindow from "@/components/ZoomWindow.vue";

const MAXIMAL_BID = 999999999;

export default defineComponent({
  name: "LotPage",
  components: { ModalWindow, ZoomWindow },
  mixins: [EndingTime],
  emits: ["jumpTo"],
  data() {
    return {
      bid: 0,
      showConfirmModal: false,
      showZoomModal: false,
      touchedInput: false,
      tabs: {
        information: true,
        details: false,
        shipping: false,
        terms: false,
      },
      flashMessage: "",
      bidError: false,
      moneyConfig: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 0,
        masked: false /* doesn't work with directive */,
        disableNegative: true,
        disabled: false,
        min: 0,
        max: MAXIMAL_BID,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
      },
    };
  },
  computed: {
    ...mapState([
      "currencyPrices",
      "lotPrices",
      "clientMaxBid",
      "isLoggedIn",
      "userLevel",
    ]),
    ...mapGetters(["physicalEndTime"]),
    bidETH(): string {
      return (this.bid / (this.currencyPrices.ethPrice || 1)).toFixed(3);
    },
    minimalBid(): number {
      return Math.max(
        1,
        Math.min(
          Math.ceil((this.lotPrices.physical.priceUSD || 0) * 1.05),
          (this.lotPrices.physical.priceUSD || 0) + 400
        )
      );
    },
    remainingTime(): {
      days: string;
      hours: string;
      minutes: string;
      seconds: string;
    } {
      return this.formatTime(this.physicalEndTime);
    },
    isWinning(): boolean {
      return (
        this.isLoggedIn &&
        firebase.auth().currentUser?.uid === this.lotPrices.physical.winnerId
      );
    },
    isOutbid(): boolean {
      return (
        this.isLoggedIn &&
        firebase.auth().currentUser?.uid !== this.lotPrices.physical.winnerId &&
        (this.$store.state.clientMaxBid || 0) > 0
      );
    },
  },
  watch: {
    minimalBid(val) {
      this.bid = val;
    },
  },
  created() {
    this.bid = this.minimalBid;
  },
  methods: {
    placeBid() {
      this.$store.dispatch(Actions.bid, this.bid);
    },
    onShowConfirmModal() {
      if (this.bid >= this.minimalBid && this.bid <= MAXIMAL_BID) {
        this.showConfirmModal = true;
      } else if (this.bid < this.minimalBid) {
        this.bidError = true;
        setTimeout(() => {
          this.bidError = false;
        }, 4000);
      }
    },
    closeCurrentError() {
      this.$store.commit(Mutations.setError, "");
    },
  },
});
</script>

<style scoped lang="scss">
.lot {
  padding: 6.75rem 0;

  &.with-message {
    padding-top: 1.25rem;
  }

  &__image {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(sm) {
      margin-top: 0;
      margin-bottom: 0;
    }

    img {
      cursor: zoom-in;
    }

    .sold {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        font-size: 5.25rem;
        color: $colorWhite;
        margin: 0;
        text-align: center;
        @include condensed;
      }
    }
  }

  &__message {
    background: #ccc;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0;
    position: relative;
    @include media-breakpoint-up(sm) {
      height: 3.75rem;
      flex-direction: row;
      padding: 0;
    }

    a {
      display: block;
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
      font-size: 0.8rem;
    }

    &__col {
      margin-bottom: 4.25rem;
      position: relative;
      @include media-breakpoint-up(sm) {
        height: 3.75rem;
      }

      &:first-of-type {
        margin-bottom: 1rem;
      }

      &.mobile {
        margin-bottom: 0;
        margin-top: 2rem;
      }
    }

    &__title {
      width: 100%;
      padding: 0 1rem;
      display: flex;
      @include media-breakpoint-up(sm) {
        width: 50%;
        display: block;
      }

      span {
        min-width: 1.5rem;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
        background: $colorBlack;
        text-align: center;
        font-family: $fontTitle;
        font-size: 1.25rem;
        color: $colorWhite;
        border-radius: 50%;
      }

      h2 {
        margin: 0 0 0 0.5rem;
        font-size: 1.5rem;
        line-height: 1.5rem !important;
        display: inline-block;
        @include media-breakpoint-up(sm) {
          line-height: 2rem !important;
          margin-left: 1.25rem;
        }
      }
    }
    p {
      width: 100%;
      padding: 0 1rem;
      font-size: 0.75rem;
      line-height: 0.9rem;
      padding-left: 3rem;
      margin: 0 !important;
      @include media-breakpoint-up(sm) {
        width: 50%;
        padding-left: 0;
      }
    }

    &.error {
      background: #ffbbbb;

      span {
        background: $colorRed;
      }
    }

    &.success {
      background: #bbffbb;

      span {
        background: #00aa00;
      }
    }
  }

  h1 {
    font-size: 3.35rem;
  }

  h2 {
    margin-bottom: 0;
    line-height: 2rem;
  }

  p {
    margin-top: 0.7rem;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      padding-left: 2rem;
    }
  }

  &__price {
    &__header {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $colorBlack;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
      }

      p {
        font-size: 0.9rem;
        line-height: 1.25rem;

        &.strong {
          font-size: 1.2rem;

          &.ending {
            color: $colorRed;
          }
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-bottom: 1px solid $colorBlack;
      margin-bottom: 2rem;
      padding: 1rem 0;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        align-items: center;
      }

      .price {
        h3 {
          font-size: 3rem;
          display: inline-block;
        }

        span {
          font-size: 1.2rem;
          color: $colorGrey;
          font-family: $fontText;
          margin-left: 0.4rem;
        }
      }

      p {
        margin-bottom: 0.7rem;
        font-size: 0.9rem;
      }
    }
  }

  &__notlogged {
    background: #eee;
    padding: 1.5rem;
    a {
      text-decoration: underline;
    }
  }

  &__bidding {
    &__inputs {
      display: flex;
      margin-bottom: 2.5rem;
      flex-direction: column;
      width: 100%;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }

      .input__info {
        display: flex;
        background: $colorWhite;
        align-items: center;
        flex-direction: column;
        padding: 0.2rem;
        font-size: 0.9rem;
        border-left: 1px solid $colorBlack;
        border-right: 1px solid $colorBlack;
        color: $colorGrey;
        @include media-breakpoint-up(sm) {
          padding: 0 0.3rem 0 0.1rem;
          border-left: 0;
          border-right: 0;
          border-bottom: 1px solid $colorBlack;
          border-top: 1px solid $colorBlack;
          flex-direction: row;
        }
        @include media-breakpoint-up(md) {
          padding: 0 0.75rem 0 0.1rem;
          font-size: 0.9rem;
        }
      }

      input {
        margin: 0;
        border-bottom: 0;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        text-align: center;
        width: 100%;
        padding: 0 0.1rem;
        font-size: 16px;
        @include media-breakpoint-up(sm) {
          text-align: right;
          border-bottom-left-radius: 0.3rem;
          border-top-right-radius: 0;
          border-right: 0;
          border-bottom: 1px solid $colorBlack;
        }
        @include media-breakpoint-up(md) {
          padding: 0 1rem;
          font-size: 1.4rem;
        }
      }

      .button {
        padding: 0;
        min-width: 5.2rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        min-height: 32px;
        height: 32px;
        line-height: 26px;
        @include media-breakpoint-up(sm) {
          border-top-right-radius: 3px;
          border-bottom-left-radius: 0;
        }
        @include media-breakpoint-up(md) {
          min-height: 3.5rem;
          min-width: 6.75rem;
        }
      }
    }

    p {
      font-size: 0.75rem;
      font-weight: 700;
      margin: 0;
      transition: all 0.2s ease-in-out;

      &.error {
        color: $colorRed;
        animation: shake 0.8s ease-in-out;
      }
    }
  }
}

@keyframes shake {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: translateX(0);
  }
  10%,
  50%,
  90% {
    transform: translateX(-3px);
  }
  30%,
  70% {
    transform: translateX(3px);
  }
}

.tabs {
  &__item {
    border-bottom: 1px solid $colorBlack;

    &:first-of-type {
      border-top: 1px solid $colorBlack;
    }

    &__title {
      padding: 2rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }

    &__content {
      overflow: hidden;
      max-height: 5000px;
      padding-bottom: 2.5rem;
      p {
        margin-bottom: 1.25rem;

        &:last-child {
          margin-bottom: 0;
        }
        a {
          display: inline;
          text-decoration: underline;
        }
      }
      a {
        display: block;
        text-decoration: underline;
      }
    }

    &__toggle {
      width: 1.5rem;
      height: 1.5rem;
      position: relative;

      &:before,
      &:after {
        display: block;
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0;
        left: 50%;
        background: $colorBlack;
      }

      &:after {
        transform: rotateZ(90deg);
      }

      &:before {
        transition: all 0.3s ease-in-out;
      }

      &.open {
        &:before {
          transform: rotateX(90deg);
        }
      }
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0;
}
</style>
