
import { defineComponent } from "vue";
import EndingTime from "@/mixins/EndingTime";
import { Actions } from "@/types/actions";
import ModalWindow from "@/components/ModalWindow.vue";
import firebase from "firebase/app";
import { mapGetters, mapState } from "vuex";
import { Mutations } from "@/types/mutations";
import ZoomWindow from "@/components/ZoomWindow.vue";

const MAXIMAL_BID = 999999999;

export default defineComponent({
  name: "LotPage",
  components: { ModalWindow, ZoomWindow },
  mixins: [EndingTime],
  emits: ["jumpTo"],
  data() {
    return {
      bid: 0,
      showConfirmModal: false,
      showZoomModal: false,
      touchedInput: false,
      tabs: {
        information: true,
        details: false,
        shipping: false,
        terms: false,
      },
      flashMessage: "",
      bidError: false,
      moneyConfig: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 0,
        masked: false /* doesn't work with directive */,
        disableNegative: true,
        disabled: false,
        min: 0,
        max: MAXIMAL_BID,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
      },
    };
  },
  computed: {
    ...mapState([
      "currencyPrices",
      "lotPrices",
      "clientMaxBid",
      "isLoggedIn",
      "userLevel",
    ]),
    ...mapGetters(["physicalEndTime"]),
    bidETH(): string {
      return (this.bid / (this.currencyPrices.ethPrice || 1)).toFixed(3);
    },
    minimalBid(): number {
      return Math.max(
        1,
        Math.min(
          Math.ceil((this.lotPrices.physical.priceUSD || 0) * 1.05),
          (this.lotPrices.physical.priceUSD || 0) + 400
        )
      );
    },
    remainingTime(): {
      days: string;
      hours: string;
      minutes: string;
      seconds: string;
    } {
      return this.formatTime(this.physicalEndTime);
    },
    isWinning(): boolean {
      return (
        this.isLoggedIn &&
        firebase.auth().currentUser?.uid === this.lotPrices.physical.winnerId
      );
    },
    isOutbid(): boolean {
      return (
        this.isLoggedIn &&
        firebase.auth().currentUser?.uid !== this.lotPrices.physical.winnerId &&
        (this.$store.state.clientMaxBid || 0) > 0
      );
    },
  },
  watch: {
    minimalBid(val) {
      this.bid = val;
    },
  },
  created() {
    this.bid = this.minimalBid;
  },
  methods: {
    placeBid() {
      this.$store.dispatch(Actions.bid, this.bid);
    },
    onShowConfirmModal() {
      if (this.bid >= this.minimalBid && this.bid <= MAXIMAL_BID) {
        this.showConfirmModal = true;
      } else if (this.bid < this.minimalBid) {
        this.bidError = true;
        setTimeout(() => {
          this.bidError = false;
        }, 4000);
      }
    },
    closeCurrentError() {
      this.$store.commit(Mutations.setError, "");
    },
  },
});
