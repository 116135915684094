import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/assets/scss/main.scss";
import money from "v-money3";
import VueCookies from "vue3-cookies";

let app: any;

if (!app) {
  app = createApp(App);
  app.use(store);
  app.use(router);
  app.use(money);
  app.use(VueCookies, { expireTimes: "30d" });
  app.config.globalProperties.window = window; //reactive(window) doesn't make org2 work
  app.mount("#app");
}
