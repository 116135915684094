w
<template>
  <header class="page__header">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <router-link
            :to="{ name: 'Home', hash: '#footer' }"
            class="page__header__link condensed"
            >Back</router-link
          >
        </div>
      </div>
    </div>
  </header>
  <div class="container page policy">
    <div class="row">
      <div class="col">
        <h1 class="text-center">Privacy Policy</h1>
        <h2>Introduction</h2>
        <p>Welcome to Winthorpe Ventures LLP’s privacy policy.</p>
        <p>
          Winthorpe Ventures LLP respects your privacy and is committed to
          protecting your personal data. This privacy policy will inform you as
          to how we look after your personal data when you visit our website
          (regardless of where you visit it from) and tell you about your
          privacy rights and how the law protects you.
        </p>
        <p>
          This privacy policy is provided in a layered format so you can click
          through to the specific areas set out below. Please also use the
          Glossary to understand the meaning of some of the terms used in this
          privacy policy.
        </p>
        <ol>
          <li>IMPORTANT INFORMATION AND WHO WE ARE</li>
          <li>THE DATA WE COLLECT ABOUT YOU</li>
          <li>HOW IS YOUR PERSONAL DATA COLLECTED?</li>
          <li>HOW WE USE YOUR PERSONAL DATA</li>
          <li>DISCLOSURES OF YOUR PERSONAL DATA</li>
          <li>INTERNATIONAL TRANSFERS</li>
          <li>DATA SECURITY</li>
          <li>DATA RETENTION</li>
          <li>YOUR LEGAL RIGHTS</li>
          <li>GLOSSARY</li>
        </ol>
        <h2>1. Important information and who we are</h2>
        <h3>Purpose of this privacy policy</h3>
        <p>
          This privacy policy aims to give you information on how Winthorpe
          Ventures LLP collects and processes your personal data through your
          use of this website, including any data you may provide through this
          website when you participate in our online auctions.
        </p>
        <p>
          This website is not intended for children and we do not knowingly
          collect data relating to children.
        </p>
        <p>
          It is important that you read this privacy policy together with any
          other privacy policy or fair processing policy we may provide on
          specific occasions when we are collecting or processing personal data
          about you so that you are fully aware of how and why we are using your
          data. This privacy policy supplements other notices and privacy
          policies and is not intended to override them.
        </p>
        <h3>Controller</h3>
        <p>
          Winthorpe Ventures LLP is the controller and responsible for your
          personal data (collectively referred to as the “Firm", "we", "us" or
          "our" in this privacy policy).
        </p>
        <h3>Contact details</h3>
        <p>
          If you have any questions about this privacy policy or our privacy
          practices, please contact our data privacy manager, Oliver Joshi in
          the following ways:
        </p>
        <p>Full name of legal entity: Winthorpe Ventures LLP</p>
        <p>
          Email address:
          <a href="mailto:olly@winthorpeventures.com"
            >olly@winthorpeventures.com</a
          >
        </p>
        <p>
          Postal address: 71-75 Shelton Street Covent Garden London WC2H 9JQ
        </p>
        <p>
          You have the right to make a complaint at any time to the Information
          Commissioner's Office (ICO), the UK regulator for data protection
          issues (www.ico.org.uk). We would, however, appreciate the chance to
          deal with your concerns before you approach the ICO so please contact
          us in the first instance.
        </p>
        <h3>
          Changes to the privacy policy and your duty to inform us of changes
        </h3>
        <p>
          We keep our privacy policy under regular review. This version was last
          updated on 23rd May 2021.
        </p>
        <p>
          It is important that the personal data we hold about you is accurate
          and current. Please keep us informed if your personal data changes
          during your relationship with us.
        </p>
        <h3>Third-party links</h3>
        <p>
          This website may include links to third-party websites, plug-ins and
          applications. Clicking on those links or enabling those connections
          may allow third parties to collect or share data about you. We do not
          control these third-party websites and are not responsible for their
          privacy statements. When you leave our website, we encourage you to
          read the privacy policy of every website you visit.
        </p>
        <h2>2. The data we collect about you</h2>
        <p>
          Personal data, or personal information, means any information about an
          individual from which that person can be identified. It does not
          include data where the identity has been removed (anonymous data).
        </p>
        <p>
          We may collect, use, store and transfer different kinds of personal
          data about you which we have grouped together as follows:
        </p>
        <ul>
          <li>
            <strong>Identity Data</strong> includes first name, last name,
            username or similar identifier, marital status, title, date of birth
            and gender.
          </li>
          <li>
            <strong>Contact Data</strong> includes billing address, delivery
            address, email address and telephone numbers.
          </li>
          <li>
            <strong>Financial Data</strong> includes bank account and payment
            card details.
          </li>
          <li>
            <strong>Transaction Data</strong> includes details about payments to
            and from you and other details of items you have purchased from us.
          </li>
          <li>
            <strong>Technical Data</strong> includes internet protocol (IP)
            address, your login data, browser type and version, time zone
            setting and location, browser plug-in types and versions, operating
            system and platform, and other technology on the devices you use to
            access this website.
          </li>
          <li>
            <strong>Profile Data</strong> includes your username and password,
            purchases made by you, your interests, preferences, feedback and
            survey responses.
          </li>
          <li>
            <strong>Usage Data</strong> includes information about how you use
            our website.
          </li>
          <li>
            <strong>Marketing and Communications Data</strong> includes your
            preferences in receiving marketing from us and our third parties and
            your communication preferences.
          </li>
        </ul>
        <p>
          We also collect, use and share <strong>Aggregated Data</strong> such
          as statistical or demographic data for any purpose. Aggregated Data
          could be derived from your personal data but is not considered
          personal data in law as this data will not directly or indirectly
          reveal your identity. For example, we may aggregate your Usage Data to
          calculate the percentage of users accessing a specific website
          feature. However, if we combine or connect Aggregated Data with your
          personal data so that it can directly or indirectly identify you, we
          treat the combined data as personal data which will be used in
          accordance with this privacy policy.
        </p>
        <p>
          We do not collect any
          <strong>Special Categories of Personal Data</strong> about you (this
          includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, information about your health, and
          genetic and biometric data). Nor do we collect any information about
          criminal convictions and offences.
        </p>
        <h3>If you fail to provide personal data</h3>
        <p>
          Where we need to collect personal data by law, or under the terms of a
          contract we have with you, and you fail to provide that data when
          requested, we may not be able to perform the contract we have or are
          trying to enter into with you (for example, to provide you with goods
          or services). In this case, we may have to cancel a product or service
          you have with us but we will notify you if this is the case at the
          time.
        </p>
        <h2>3. How is your personal data collected?</h2>
        <p>
          We use different methods to collect data from and about you including
          through:
        </p>
        <ul>
          <li>
            <strong>Direct interactions.</strong> You may give us your Identity,
            Contact and Financial Data via the website. This includes personal
            data you provide when you sign up to either of our auctions:
            <ul>
              <li>Place a bid on our auctions;</li>
              <li>create an account on our website;</li>
              <li>subscribe to our service or publications;</li>
              <li>give us feedback or contact us.</li>
            </ul>
          </li>
          <li>
            <strong>Automated technologies or interactions.</strong> As you
            interact with our website, we will automatically collect Technical
            Data about your equipment, browsing actions and patterns. We collect
            this personal data by using cookies, server logs and other similar
            technologies. We may also receive Technical Data about you if you
            visit other websites employing our cookies. Please see our
            <router-link :to="{ name: 'CookiesPolicy' }"
              >cookie policy</router-link
            >
            for further details.
          </li>
          <li>
            <strong>Third parties or publicly available sources.</strong> We may
            receive personal data about you from various third parties as set
            out below: <br />Technical Data from the following parties:
            <br />(a)analytics providers such as Google based outside the UK;
            and <br />(b)search information providers.
          </li>
          <li>
            Contact, Financial and Transaction Data from providers of technical,
            payment and delivery services (such as Stripe) based inside the UK.
          </li>
          <li>
            Identity and Contact Data from data brokers or aggregators such as
            Google based outside the UK.
          </li>
        </ul>
        <h2>4. How we use your personal data</h2>
        <p>
          We will only use your personal data when the law allows us to. Most
          commonly, we will use your personal data in the following
          circumstances:
        </p>
        <ul>
          <li>
            Where we need to perform the contract we are about to enter into or
            have entered into with you.
          </li>
          <li>
            Where it is necessary for our legitimate interests (or those of a
            third party) and your interests and fundamental rights do not
            override those interests.
          </li>
          <li>Where we need to comply with a legal obligation.</li>
        </ul>
        <p>
          <a
            v-scroll-to="{
              el: '#lawful',
              offset: -92,
            }"
            href="#"
            >Click here</a
          >
          to find out more about the types of lawful basis that we will rely on
          to process your personal data.
        </p>
        <p></p>
        Generally, we do not rely on consent as a legal basis for processing
        your personal data although we will get your consent before sending
        third party direct marketing communications to you via email or text
        message. You have the right to withdraw consent to marketing at any time
        by contacting us.
        <h3>Purposes for which we will use your personal data</h3>
        <p>
          We have set out below, in a table format, a description of all the
          ways we plan to use your personal data, and which of the legal bases
          we rely on to do so. We have also identified what our legitimate
          interests are where appropriate.
        </p>
        <p>
          Note that we may process your personal data for more than one lawful
          ground depending on the specific purpose for which we are using your
          data. Please contact us if you need details about the specific legal
          ground we are relying on to process your personal data where more than
          one ground has been set out in the table below.
        </p>
        <table>
          <tr>
            <th>Purpose/Activity</th>
            <th>Type of data</th>
            <th>
              Lawful basis for processing including basis of legitimate interest
            </th>
          </tr>
          <tr>
            <td>To register you to bid on the physical auction</td>
            <td>
              (a) Identity<br />
              (b) Contact
            </td>
            <td>
              (a) Necessary for our legitimate interests to identify potential
              bidders<br />
              (b) Performance of a contract with the winning bidders"
            </td>
          </tr>
          <tr>
            <td>
              To process and deliver your order including:<br />
              (a) Manage payments, fees and charges<br />
              (b) Collect and recover money owed to us<br />
              (c) placing a hold on funds in your account upon registering and
              releasing those funds back to you as appropriate.
            </td>
            <td>
              (a) Identity<br />
              (b) Contact<br />
              (c) Financial<br />
              (d) Transaction<br />
              (e) Marketing and Communications
            </td>
            <td>
              (a) Performance of a contract with you<br />
              (b) Necessary for our legitimate interests (to recover debts due
              to us)
            </td>
          </tr>
          <tr>
            <td>
              To manage our relationship with you which will include:<br />
              (a) Notifying you about changes to our terms or privacy policy<br />
              (b) Asking you to leave a review or take a survey
            </td>
            <td>
              (a) Identity<br />
              (b) Contact<br />
              (c) Profile<br />
              (d) Marketing and Communications
            </td>
            <td>
              (a) Necessary to comply with a legal obligation<br />
              (b) Necessary for our legitimate interests (to keep our records
              updated and to study how customers use our products/services)
            </td>
          </tr>
          <tr>
            <td>
              To administer and protect our business and this website (including
              troubleshooting, data analysis, testing, system maintenance,
              support, reporting and hosting of data)
            </td>
            <td>
              (a) Identity<br />
              (b) Contact<br />
              (c) Technical
            </td>
            <td>
              (a) Necessary for our legitimate interests (for running our
              business, provision of administration and IT services, network
              security, to prevent fraud and in the context of a business
              reorganisation or group restructuring exercise)<br />
              (b) Necessary to comply with a legal obligation
            </td>
          </tr>
          <tr>
            <td>
              To deliver relevant website content and advertisements to you and
              measure or understand the effectiveness of the advertising we
              serve to you
            </td>
            <td>
              (a) Identity<br />
              (b) Contact<br />
              (c) Profile<br />
              (d) Usage<br />
              (e) Marketing and Communications<br />
              (f) Technical
            </td>
            <td>
              Necessary for our legitimate interests (to study how customers use
              our products/services, to develop them, to grow our business and
              to inform our marketing strategy)
            </td>
          </tr>
          <tr>
            <td>
              To use data analytics to improve our website, products/services,
              marketing, customer relationships and experiences
            </td>
            <td>(a) Technical<br />(b) Usage</td>
            <td>
              Necessary for our legitimate interests (to define types of
              customers for our items, to keep our website updated and relevant,
              to develop our business and to inform our marketing strategy)
            </td>
          </tr>
          <tr>
            <td>
              To make suggestions and recommendations to you about items that
              may be of interest to you
            </td>
            <td>
              (a) Identity<br />
              (b) Contact<br />
              (c) Technical<br />
              (d) Usage<br />
              (e) Profile<br />
              (f) Marketing and Communications
            </td>
            <td>
              Necessary for our legitimate interests (to develop and grow our
              business)
            </td>
          </tr>
        </table>
        <h3>Marketing</h3>
        <p>
          We strive to provide you with choices regarding certain personal data
          uses, particularly around marketing and advertising.
        </p>
        <h3>Promotional offers from us</h3>
        <p>
          We may use your Identity, Contact, Technical, Usage and Profile Data
          to form a view on what we think you may want or need, or what may be
          of interest to you. This is how we decide which products, services and
          offers may be relevant for you (we call this marketing).
        </p>
        <p>
          You will receive marketing communications from us if you have
          requested information from us or purchased items from us and you have
          not opted out of receiving that marketing.
        </p>
        <h3>Third-party marketing</h3>
        <p>
          We will get your express opt-in consent before we share your personal
          data with any third party for marketing purposes.
        </p>
        <h3>Opting out</h3>
        <p>
          You can ask us or third parties to stop sending you marketing messages
          at any time by contacting us at any time./
        </p>
        <h3>Cookies</h3>
        <p>
          You can set your browser to refuse all or some browser cookies, or to
          alert you when websites set or access cookies. If you disable or
          refuse cookies, please note that some parts of this website may become
          inaccessible or not function properly. For more information about the
          cookies we use, please see
          <router-link :to="{ name: 'CookiesPolicy' }"
            >our cookies policy</router-link
          >.
        </p>
        <h3>Change of purpose</h3>
        <p>
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If you wish to get an explanation as to how the processing
          for the new purpose is compatible with the original purpose, please
          contact us.
        </p>
        <p>
          If we need to use your personal data for an unrelated purpose, we will
          notify you and we will explain the legal basis which allows us to do
          so.
        </p>
        <p>
          Please note that we may process your personal data without your
          knowledge or consent, in compliance with the above rules, where this
          is required or permitted by law.
        </p>
        <h2>5. Disclosures of your personal data</h2>
        <p>
          We may share your personal data with the parties set out below for the
          purposes set out in the table (Purposes for which we will use your
          personal data) above.
        </p>
        <ul>
          <li>
            External Third Parties as set out in the
            <a
              v-scroll-to="{
                el: '#lawful',
                offset: -92,
              }"
              href="#"
              >Glossary</a
            >.
          </li>
          <li>
            Specific third parties such as MailChimp, Mailgun, Stripe, Google
            (IAP) and Zendesk.
          </li>
          <li>
            Third parties to whom we may choose to sell, transfer or merge parts
            of our business or our assets. Alternatively, we may seek to acquire
            other businesses or merge with them. If a change happens to our
            business, then the new owners may use your personal data in the same
            way as set out in this privacy policy.
          </li>
        </ul>
        <p>
          We require all third parties to respect the security of your personal
          data and to treat it in accordance with the law. We do not allow our
          third-party service providers to use your personal data for their own
          purposes and only permit them to process your personal data for
          specified purposes and in accordance with our instructions.
        </p>
        <h2>6. International transfers</h2>
        <p>
          Many of our external third parties are based outside the UK so their
          processing of your personal data will involve a transfer of data
          outside the UK. Please contact us if you want further information on
          the specific mechanism used by us when transferring your personal data
          out of the UK.
        </p>
        <h2>7. Data security</h2>
        <p>
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used or accessed in an
          unauthorised way, altered or disclosed. In addition, we limit access
          to your personal data to those employees, agents, contractors and
          other third parties who have a business need to know. They will only
          process your personal data on our instructions and they are subject to
          a duty of confidentiality.
        </p>
        <p>
          We have put in place procedures to deal with any suspected personal
          data breach and will notify you and any applicable regulator of a
          breach where we are legally required to do so.
        </p>
        <h2>8. Data retention</h2>
        <h3>How long will you use my personal data for?</h3>
        <p>
          We will only retain your personal data for as long as reasonably
          necessary to fulfil the purposes we collected it for, including for
          the purposes of satisfying any legal, regulatory, tax, accounting or
          reporting requirements. We may retain your personal data for a longer
          period in the event of a complaint or if we reasonably believe there
          is a prospect of litigation in respect to our relationship with you.
        </p>
        <p>
          To determine the appropriate retention period for personal data, we
          consider the amount, nature and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal, regulatory, tax, accounting or other requirements.
        </p>
        <p>
          By law we have to keep basic information about our customers
          (including Contact, Identity, Financial and Transaction Data) for six
          years after they cease being customers for tax purposes.
        </p>
        <p>
          In some circumstances you can ask us to delete your data: see
          <a
            v-scroll-to="{
              el: '#rights',
              offset: -92,
            }"
            href="#"
            >your legal rights</a
          >
          below for further information.
        </p>
        <p>
          In some circumstances we will anonymise your personal data (so that it
          can no longer be associated with you) for research or statistical
          purposes, in which case we may use this information indefinitely
          without further notice to you.
        </p>
        <h2>9. Your legal rights</h2>
        <p>
          Under certain circumstances, you have rights under data protection
          laws in relation to your personal data. Please click on the links
          below to find out more about these rights:
        </p>
        <ul class="italic">
          <li>Request access to your personal data.</li>
          <li>Request correction of your personal data.</li>
          <li>Request erasure of your personal data.</li>
          <li>Object to processing of your personal data.</li>
          <li>Request restriction of processing your personal data.</li>
          <li>Request transfer of your personal data.</li>
          <li>Right to withdraw consent.</li>
        </ul>
        <p>
          If you wish to exercise any of the rights set out above, please
          contact us.
        </p>
        <h3>No fee usually required</h3>
        <p>
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee if your request is clearly unfounded, repetitive or excessive.
          Alternatively, we could refuse to comply with your request in these
          circumstances.
        </p>
        <h3>What we may need from you</h3>
        <p>
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access your personal
          data (or to exercise any of your other rights). This is a security
          measure to ensure that personal data is not disclosed to any person
          who has no right to receive it. We may also contact you to ask you for
          further information in relation to your request to speed up our
          response.
        </p>
        p>
        <h3>Time limit to respond</h3>
        <p>
          We try to respond to all legitimate requests within one month.
          Occasionally it could take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you and keep you updated.
        </p>
        <h2 id="lawful">10. Glossary</h2>
        <h3>LAWFUL BASIS</h3>
        <p>
          <strong>Legitimate Interest</strong> means the interest of our
          business in conducting and managing our business to enable us to give
          you the best service/product and the best and most secure experience.
          We make sure we consider and balance any potential impact on you (both
          positive and negative) and your rights before we process your personal
          data for our legitimate interests. We do not use your personal data
          for activities where our interests are overridden by the impact on you
          (unless we have your consent or are otherwise required or permitted to
          by law). You can obtain further information about how we assess our
          legitimate interests against any potential impact on you in respect of
          specific activities by contacting us.
        </p>
        <p>
          <strong>Performance of Contract</strong> means processing your data
          where it is necessary for the performance of a contract to which you
          are a party or to take steps at your request before entering into such
          a contract.
        </p>
        <p>
          <strong>Comply with a legal obligation</strong> means processing your
          personal data where it is necessary for compliance with a legal
          obligation that we are subject to.
        </p>
        <h3>THIRD PARTIES</h3>
        <p><strong>External Third Parties</strong></p>
        <ul>
          <li>
            Service providers acting as processors based in the United Kingdom
            and the United States who provide IT and system administration
            services.
          </li>
          <li>
            Professional advisers acting as processors or joint controllers
            including lawyers, bankers, auditors and insurers based in the
            United Kingdom who provide consultancy, banking, legal, insurance
            and accounting services.
          </li>
          <li>
            HM Revenue & Customs, regulators and other authorities acting as
            processors or joint controllers based in the United Kingdom who
            require reporting of processing activities in certain circumstances.
          </li>
          <li>
            Snoofa Limited based in the United Kingdom who provide auction
            software and hosting in respect of our physical item auction.
          </li>
          <li>
            Rarible Inc based in the United States of America who provide
            auction software and hosting in respect of our Non-fungible Token
            (NFT) auction.
          </li>
        </ul>
        <h3 id="rights">YOUR LEGAL RIGHTS</h3>
        <p>You have the right to:</p>
        <p>
          <strong>Request access</strong> to your personal data (commonly known
          as a "data subject access request"). This enables you to receive a
          copy of the personal data we hold about you and to check that we are
          lawfully processing it.
        </p>
        <p>
          <strong>Request correction</strong> of the personal data that we hold
          about you. This enables you to have any incomplete or inaccurate data
          we hold about you corrected, though we may need to verify the accuracy
          of the new data you provide to us.
        </p>
        <p>
          <strong>Request erasure</strong> of your personal data. This enables
          you to ask us to delete or remove personal data where there is no good
          reason for us continuing to process it. You also have the right to ask
          us to delete or remove your personal data where you have successfully
          exercised your right to object to processing (see below), where we may
          have processed your information unlawfully or where we are required to
          erase your personal data to comply with local law. Note, however, that
          we may not always be able to comply with your request of erasure for
          specific legal reasons which will be notified to you, if applicable,
          at the time of your request.
        </p>
        <p>
          <strong>Object to processing</strong> of your personal data where we
          are relying on a legitimate interest (or those of a third party) and
          there is something about your particular situation which makes you
          want to object to processing on this ground as you feel it impacts on
          your fundamental rights and freedoms. You also have the right to
          object where we are processing your personal data for direct marketing
          purposes. In some cases, we may demonstrate that we have compelling
          legitimate grounds to process your information which override your
          rights and freedoms.
        </p>
        <p>
          <strong>Request restriction of processing</strong> of your personal
          data. This enables you to ask us to suspend the processing of your
          personal data in the following scenarios:
        </p>
        <ul>
          <li>If you want us to establish the data's accuracy.</li>
          <li>
            Where our use of the data is unlawful but you do not want us to
            erase it.
          </li>
          <li>
            Where you need us to hold the data even if we no longer require it
            as you need it to establish, exercise or defend legal claims.
          </li>
          <li>
            You have objected to our use of your data but we need to verify
            whether we have overriding legitimate grounds to use it.
          </li>
        </ul>
        <p>
          <strong>Request the transfer</strong> of your personal data to you or
          to a third party. We will provide to you, or a third party you have
          chosen, your personal data in a structured, commonly used,
          machine-readable format. Note that this right only applies to
          automated information which you initially provided consent for us to
          use or where we used the information to perform a contract with you.
        </p>
        <p>
          <strong>Withdraw consent at any time</strong> where we are relying on
          consent to process your personal data. However, this will not affect
          the lawfulness of any processing carried out before you withdraw your
          consent. If you withdraw your consent, we may not be able to provide
          certain products or services to you. We will advise you if this is the
          case at the time you withdraw your consent.
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VueScrollTo from "vue-scrollto";

export default defineComponent({
  name: "PrivacyPolicy",
  directives: {
    scrollTo: VueScrollTo,
  },
  created() {
    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.goBack();
      }
    });
  },
  unmounted() {
    document.removeEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.goBack();
      }
    });
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
});
</script>

<style scoped lang="scss">
.policy {
  font-size: 0.8rem;

  ol {
    font-weight: bold;
  }

  table {
    margin-bottom: 1rem;
    tr {
      th,
      td {
        border: 1px solid $colorGrey;
        padding: 0.2rem;
        vertical-align: top;
      }
    }
  }
}
</style>
