<template>
  <div id="footer" class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-7 col-12">
          <h3>Steve Jobs’ Job Application</h3>
          <div class="footer__social">
            <a href="https://twitter.com/SJJA_Auction" target="_blank"
              ><span class="icon-twitter-square"></span
            ></a>
            <a href="https://www.instagram.com/sjja_auction" target="_blank"
              ><span class="icon-instagram"></span
            ></a>
          </div>
          <div class="footer__email">
            Email:
            <a href="mailto:info@stevejobsjobapplication.com"
              >info@stevejobsjobapplication.com</a
            >
          </div>
        </div>
        <div class="col-md-5 col-12">
          <div class="row footer__links">
            <div class="col-12 col-sm-4">
              <router-link :to="{ name: 'About' }" class="footer__link"
                >About Us</router-link
              >
              <router-link :to="{ name: 'Faq' }" class="footer__link"
                >FAQ</router-link
              >
              <router-link :to="{ name: 'AuctionTerms' }" class="footer__link"
                >Website terms</router-link
              >
            </div>
            <div class="col">
              <router-link :to="{ name: 'PrivacyPolicy' }" class="footer__link"
                >Privacy policy</router-link
              >
              <router-link :to="{ name: 'CookiesPolicy' }" class="footer__link"
                >Cookies policy</router-link
              >
              <router-link :to="{ name: 'SaleTerms' }" class="footer__link"
                >Terms & Conditions of Sale</router-link
              >
            </div>
          </div>
          <p class="footer__info">
            © 2021 Winthorpe Ventures LLP. All rights reserved.<br />
            Registered number: OC436607.<br />
            Registered office: 71-75 Shelton Street, Covent Garden, London,
            England, WC2H 9JQ
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterBlock",
});
</script>

<style scoped lang="scss">
.footer {
  padding: 2.5rem 3rem 7.5rem;
  background: $colorBlack;
  text-align: center;
  margin-top: 3rem;
  @include media-breakpoint-up(sm) {
    text-align: left;
  }

  h3 {
    color: $colorWhite;
    font-size: 3rem;
    line-height: 3rem;
    transform-origin: top left;
    margin-bottom: 2.5rem;
  }

  &__social {
    margin-bottom: 2.5rem;
    a {
      display: inline-block;
      margin: 0 0.6rem;
      font-size: 4rem;
      color: $colorWhite !important;
      @include media-breakpoint-up(sm) {
        font-size: 2rem;
        margin: 0 0.3rem;
      }
    }
  }

  &__email {
    color: $colorWhite;
    margin-bottom: 3rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
    a {
      color: $colorWhite !important;
    }
  }

  &__link {
    color: $colorWhite !important;
    display: block;
  }

  &__info {
    color: #666;
    margin-top: 5rem;
    font-size: 0.9rem;
  }

  &__links {
    flex-direction: column;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
  }
}
</style>
