import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterScrollBehavior,
} from "vue-router";
import Home from "../views/Home.vue";
import About from "@/views/About.vue";
import AuctionTerms from "@/views/AuctionTerms.vue";
import Faq from "@/views/Faq.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import Authenticity from "@/views/Authenticity.vue";
import Snoofa from "@/views/Snoofa.vue";
import LotPage from "@/pages/LotPage.vue";
import Test from "@/views/Test.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import SaleTerms from "@/views/SaleTerms.vue";
import CookiesPolicy from "@/views/CookiesPolicy.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/website-terms",
    name: "AuctionTerms",
    component: AuctionTerms,
  },
  {
    path: "/sale-terms-and-conditions",
    name: "SaleTerms",
    component: SaleTerms,
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/cookies-policy",
    name: "CookiesPolicy",
    component: CookiesPolicy,
  },
  {
    path: "/authenticity",
    name: "Authenticity",
    component: Authenticity,
  },
  {
    path: "/snoofa",
    name: "Snoofa",
    component: Snoofa,
    children: [
      {
        path: "",
        name: "Lot",
        component: LotPage,
      },
    ],
  },
  { path: "/:pathMatch(.*)*", component: ErrorPage },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: function (to) {
    if (to.hash) {
      return { el: to.hash };
    }
  } as RouterScrollBehavior,
  routes,
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});
export default router;
