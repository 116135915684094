<template>
  <div :class="['modal__wrapper', { white: white }]">
    <div class="modal__wrapper__close" @click="$emit('close')"></div>
    <div class="modal">
      <button class="modal__close" @click="$emit('close')"></button>
      <div class="modal__content">
        <slot name="content">
          <p>Default content</p>
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModalWindow",
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  created() {
    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.$emit("close");
      }
    });
  },
  unmounted() {
    document.removeEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.$emit("close");
      }
    });
  },
});
</script>

<style lang="scss">
.modal {
  background: $colorBlack;
  padding: 2.8rem 0.8rem;
  color: $colorWhite;
  position: relative;
  max-height: 30rem;
  @include media-breakpoint-up(sm) {
    padding: 2.8rem;
    max-height: none;
    //max-width: 25rem;
  }

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;

    &.white {
      background: rgba(0, 0, 0, 0.3);

      .modal {
        background: $colorWhite;
        color: $colorBlack;
        max-width: 31.25rem;

        &__close {
          background-image: linear-gradient($colorBlack 0.2rem, transparent 0),
            linear-gradient($colorBlack 4rem, transparent 0);
        }
      }
    }

    &__close {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__close {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    background-repeat: no-repeat;
    background-image: linear-gradient($colorWhite 0.2rem, transparent 0),
      linear-gradient($colorWhite 4rem, transparent 0);
    background-size: 2rem 0.1rem, 0.1rem 2rem;
    background-position: 50% 50%, 50% 50%;
    transform: rotateZ(45deg);
    cursor: pointer;
  }

  &__content {
    max-height: 24.4rem;
    overflow-x: visible;
    overflow-y: auto;
    @include media-breakpoint-up(sm) {
      max-height: none;
    }
    h2 {
      text-align: center;
      margin-bottom: 2rem;
    }

    h3 {
      font-size: 1.5rem;
      text-align: center;
    }

    p {
      max-width: 35rem;
    }

    &__price {
      font-size: 4.5rem;
      text-align: center;
      margin-bottom: 2rem;
      @include condensed;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
