export enum Mutations {
  setCurrencyPrices = "SET_CURRENCY_PRICES",
  setLotPrices = "SET_LOT_PRICES",
  setToken = "SET_TOKEN",
  setDate = "SET_DATE",
  setError = "SET_ERROR",
  setLoggedIn = "SET_LOGGED_IN",
  setTopBid = "SET_TOP_BID",
  setUserLevel = "SET_USER_LEVEL",
  setCountries = "SET_COUNTRIES",
  setCounties = "SET_COUNTIES",
  setPhonePrefixes = "SET_PHONE_PREFIXES",
  setUserData = "SET_USER_DATA",
  setPoll = "SET_POLL",
  setTimeOffset = "SET_TIME_OFFSET",
}
