<template>
  <div class="zoom__wrapper">
    <div
      class="zoom__wrapper__close"
      @click="
        $emit('close');
        dragging = false;
      "
    ></div>
    <div class="zoom">
      <button
        class="zoom__close"
        @click="
          $emit('close');
          dragging = false;
        "
      ></button>
      <div
        ref="mask"
        class="zoom__content"
        @mouseover="dragging = true"
        @mousemove="dragImage($event)"
        @mouseleave="dragging = false"
      >
        <img
          ref="image"
          src="application-big.jpg"
          draggable="false"
          alt="Steve Jobs’ Job Application"
          @click.right.prevent
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ZoomWindow",
  emits: ["close"],
  data() {
    return {
      dragging: false,
    };
  },
  computed: {
    maskWidth(): number {
      return (this.$refs.mask as HTMLElement).offsetWidth;
    },
    maskHeight(): number {
      return (this.$refs.mask as HTMLElement).offsetHeight;
    },
    imgWidth(): number {
      return (this.$refs.image as HTMLElement).offsetWidth;
    },
    imgHeight(): number {
      return (this.$refs.image as HTMLElement).offsetHeight;
    },
  },
  created() {
    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.dragging = false;
        this.$emit("close");
      }
    });
  },
  unmounted() {
    document.removeEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.dragging = false;
        this.$emit("close");
      }
    });
  },
  methods: {
    dragImage(e: MouseEvent) {
      if (this.dragging) {
        let relX, relY, img, maskRect, mask;
        mask = this.$refs.mask as HTMLElement;
        if (mask === null) return;
        maskRect = mask.getBoundingClientRect();
        img = this.$refs.image as HTMLElement;
        relX = e.x - maskRect.x;
        relY = e.y - maskRect.y;
        img.style.left =
          "-" +
          ((100 * relX) / this.maskWidth) *
            (this.imgWidth / this.maskWidth - 1) +
          "%";
        img.style.top =
          "-" +
          ((100 * relY) / this.maskHeight) *
            (this.imgHeight / this.maskHeight - 1) +
          "%";
      }
    },
  },
});
</script>

<style lang="scss">
.zoom {
  background: $colorBlack;
  color: $colorWhite;
  width: 1200px;
  height: 1200px;
  max-height: 80%;
  max-width: 80%;
  position: relative;

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;

    &__close {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    left: -30px;
    width: 30px;
    height: 30px;
    border: 0;
    overflow: hidden;
    background: $colorBlack;

    &:before,
    &:after {
      display: block;
      content: "";
      position: absolute;
      width: 1px;
      height: 100%;
      background: $colorWhite;
      top: 4px;
    }

    &:before {
      left: 4px;
      transform-origin: top left;
      transform: rotateZ(-45deg);
    }

    &:after {
      right: 4px;
      transform-origin: top right;
      transform: rotateZ(45deg);
    }
  }

  &__content {
    overflow: scroll;
    width: 100%;
    height: 100%;
    position: relative;
    @include media-breakpoint-up(md) {
      overflow: hidden;
    }

    img {
      position: static;
      top: 0;
      left: 0;
      width: 1200px;
      max-width: none;
      @include media-breakpoint-up(md) {
        position: absolute;
        width: 2500px;
      }
    }
  }
}
</style>
