<template>
  <div class="auction__details">
    <div class="row">
      <div v-if="secondsToEnd > 0" class="col-12 col-sm-5">
        Current Bid ({{ isDigital ? "ETH" : "USD" }})
      </div>
      <div v-if="secondsToEnd <= 0" class="col-12 col-sm-5">
        Last Bid ({{ isDigital ? "ETH" : "USD" }})
      </div>
      <div
        v-if="secondsToEnd > 0"
        :class="[
          'col-12',
          'col-sm-7',
          'text-right',
          'strong',
          { ending: secondsToEnd <= 600 },
        ]"
      >
        Ending in: {{ remainingTime.days }}d {{ remainingTime.hours }}h
        {{ remainingTime.minutes }}m {{ remainingTime.seconds }}s
      </div>
      <div v-else class="col-12 col-sm-7 text-right strong">Ended</div>
    </div>
    <div class="divider"></div>
    <div class="row">
      <div class="col">
        {{ auctionData.bidsNumber }}
        Bid{{ auctionData.bidsNumber !== 1 ? "s" : "" }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-7">
        <span v-if="isDigital" class="price bold">
          <span class="eth">Ξ</span>
          {{
            auctionData.priceETH
              .toFixed(2)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          }}
        </span>
        <span v-else class="price bold">
          <span>$</span>
          {{
            auctionData.priceUSD
              .toFixed(2)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          }}
        </span>
        <span v-if="!isDigital" class="show-xs mb-2"
          >(<span class="eth">Ξ</span>
          {{
            (auctionData.priceUSD / currencyPrices.ethPrice)
              .toFixed(2)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          }})</span
        >
        <span v-else class="show-xs mb-2">
          (<span>$</span>
          {{
            (auctionData.priceETH * currencyPrices.ethPrice)
              .toFixed(2)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          }})
        </span>
      </div>
      <div class="col-12 col-sm text-right">
        <a
          v-if="isDigital"
          target="_blank"
          href="https://rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:1134816"
          :class="[
            'button',
            {
              primary: secondsToEnd > 0,
              secondary: secondsToEnd <= 0,
            },
          ]"
        >
          <span>{{ secondsToEnd > 0 ? "Place bid" : "View lot" }}</span>
        </a>
        <router-link
          v-else
          :to="{ name: 'Lot' }"
          :class="[
            'button',
            {
              primary: secondsToEnd > 0,
              secondary: secondsToEnd <= 0,
            },
          ]"
        >
          <span>{{ secondsToEnd > 0 ? "Place bid" : "View lot" }}</span>
        </router-link>
      </div>
    </div>
    <div class="row mt-3 mt-sm-0">
      <div v-if="!isDigital" class="col">
        <span class="hide-xs d-sm-inline-block"
          >(<span class="eth">Ξ</span>
          {{
            (auctionData.priceUSD / currencyPrices.ethPrice)
              .toFixed(2)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          }})</span
        >
      </div>
      <div v-else class="col">
        <span class="hide-xs d-sm-inline-block"
          >(<span>$</span>
          {{
            (auctionData.priceETH * currencyPrices.ethPrice)
              .toFixed(2)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          }})</span
        >
      </div>
    </div>
    <div class="divider"></div>
    <div class="row">
      <div class="col-12 col-sm">
        <span class="edition">Edition 1 of 1</span>
      </div>
      <div class="col-12 col-sm text-right">
        <a href="#" class="button secondary" @click.prevent="showModal = true"
          ><span>Description</span></a
        >
      </div>
    </div>
  </div>
  <transition name="fade">
    <modal-window v-if="showModal" @close="showModal = false">
      <template v-if="isDigital" #content>
        <h2>Digital</h2>
        <p>
          The Steve Jobs’ Job Application from 1973 is a unique piece of history
          from the exact moment that a dreamer changed the world. It’s a
          snapshot into the mind of a future genius at a moment where any small
          deviation from the path ahead, would have meant a very different world
          today.
        </p>

        <p>
          The physical application has already been to auction 3 times since it
          first went on sale with Bonhams, New York and has seen an approximate
          1,200% increase in value over the past 4 years.
        </p>

        <p>
          As legal owners of the physical item, we have now minted the
          application as a unique NFT on Rarible.com. This will be the first
          time the NFT version has gone to auction.
        </p>

        <p>
          If you win this lot, you will win the Steve Jobs' job application NFT.
        </p>
      </template>
      <template v-else #content>
        <h2>Physical</h2>
        <p>
          This extremely rare collectable first went up for auction in 2017 with
          Bonhams, New York. Since then it has gone through 2 subsequent
          auctions, increasing approximately 1,200% in value over the 4 year
          period.
        </p>
        <p>
          The Steve Jobs’ Job Application from 1973 is a unique piece of history
          from the exact moment that a dreamer changed the world. It’s a
          snapshot into the mind of a future genius at a moment where any small
          deviation from the path ahead, would have meant a very different world
          today.
        </p>
        <p>
          The application dimensions are approximately 210 x 297 mm (A4 size).
        </p>
        <p>
          It has been independently verified by both PSA DNA and Beckett
          Authentication.
        </p>
        <p>If you win this lot, you will win the physical item.</p>
      </template>
    </modal-window>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Price } from "@/types/price";
import EndingTime from "@/mixins/EndingTime";
import ModalWindow from "@/components/ModalWindow.vue";
import { mapGetters, mapState } from "vuex";

export default defineComponent({
  name: "AuctionDetail",
  components: {
    ModalWindow,
  },
  mixins: [EndingTime],
  props: {
    auctionData: {
      type: Object as () => Price,
      default: () => ({
        priceETH: 0,
        priceUSD: 0,
        endTimestamp: { seconds: 0 },
        bidsNumber: 0,
        reserveMet: false,
      }),
    },
    isDigital: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapState(["currencyPrices"]),
    ...mapGetters(["physicalEndTime", "digitalEndTime"]),
    secondsToEnd(): number {
      if (this.isDigital) {
        return this.digitalEndTime;
      } else {
        return this.physicalEndTime;
      }
    },
    remainingTime(): {
      days: string;
      hours: string;
      minutes: string;
      seconds: string;
    } {
      return this.formatTime(this.secondsToEnd);
    },
  },
});
</script>

<style scoped lang="scss">
.auction__details {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  font-size: 1rem;
  text-align: center;
  @include media-breakpoint-up(sm) {
    text-align: unset;
  }

  .text-right {
    text-align: center;
    @include media-breakpoint-up(sm) {
      text-align: right;
    }
  }

  @include media-breakpoint-up(sm) {
    font-size: 0.8rem;
  }

  .divider {
    margin: 0.6rem 0;
    height: 1px;
    background: $colorBlack;
  }

  .price {
    font-size: 2.4rem;
    @include condensed;
  }

  .button {
    margin-top: 0.3rem;
  }

  .edition {
    font-size: 1.75rem;
    line-height: 3rem;
    @include condensed;
  }

  .ending {
    color: $colorRed;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
