
import { defineComponent } from "vue";
import { mapState } from "vuex";
import AuctionDetail from "@/components/AuctionDetail.vue";
import EndingTime from "@/mixins/EndingTime";
import VueScrollTo from "vue-scrollto";
import ModalWindow from "@/components/ModalWindow.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import ZoomWindow from "@/components/ZoomWindow.vue";
import AssetsPoll from "@/components/AssetsPoll.vue";

export default defineComponent({
  name: "Home",
  directives: {
    scrollTo: VueScrollTo,
  },
  components: {
    AssetsPoll,
    FooterBlock,
    AuctionDetail,
    ModalWindow,
    ZoomWindow,
  },
  mixins: [EndingTime],
  data() {
    return {
      showCountdown: false,
      isScrolled: false,
      showInformation: false,
      showMenu: false,
      showShareModal: false,
      showZoomModal: false,
      shareCopied: false,
    };
  },
  computed: {
    ...mapState(["lotPrices", "currencyPrices"]),
    priceRatio: function () {
      const digitalPrice = Math.max(
        1,
        this.lotPrices.digital.priceETH * this.currencyPrices.ethPrice
      );
      const physicalPrice = Math.max(1, this.lotPrices.physical?.priceUSD);
      return Math.floor(
        (1 - digitalPrice / (physicalPrice + digitalPrice)) * 100
      );
    },
  },
  watch: {
    showShareModal() {
      this.shareCopied = false;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.checkScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.checkScroll);
  },
  methods: {
    checkScroll() {
      const scrollTop = document.documentElement.scrollTop;
      this.isScrolled = scrollTop > 0;
      if (this.totalEndTime > 0) {
        this.showCountdown =
          scrollTop > (this.$refs.countdown as HTMLElement).offsetTop;
      } else {
        this.showCountdown = false;
      }
    },
    copyUrl() {
      (this.$refs.urlInput as HTMLInputElement).select();
      (this.$refs.urlInput as HTMLInputElement).setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.shareCopied = true;
    },
  },
});
