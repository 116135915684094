<template>
  <header class="page__header">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <router-link
            :to="{ name: 'Home', hash: '#footer' }"
            class="page__header__link condensed"
            >Back</router-link
          >
        </div>
      </div>
    </div>
  </header>
  <div class="container page policy">
    <div class="row">
      <div class="col">
        <h1 class="text-center">Terms and Conditions of Sale</h1>
        <p class="strong">
          Please ensure you have read and understood these Terms and Conditions
          of Sale before you place a bid on the lot as they set out the terms
          and conditions on which we, Winthorpe Ventures LLP a limited liability
          partnership registered in England and Wales at 71-75 Shelton Street,
          Covent Garden, London, England, WC2H 9JQ and registered number
          OC436607 (“Seller”, “we” or “us”) offer the lot for sale. By
          registering to bid or placing a bid on the lot, you acknowledge and
          agree that these Terms and Conditions of Sale create a legally binding
          contract with you (“bidder”, “Buyer” or “you” as applicable).
        </p>
        <h2>1. Definitions</h2>
        <ol>
          <li>
            <p>
              In these Terms and Conditions of Sale, the following expressions
              have the following meanings:
            </p>

            <p>
              <strong>“Auction”</strong> means the auction for the sale of the
              Lot which will take place on the Website between 21st June 2021 at
              09.41 PDT and 28th June 2021 at 14.00 PDT, unless extended
              pursuant to Clause 4.5;
            </p>

            <p>
              <strong>“Buyer’s Premium”</strong> has the meaning set out in
              Clause 7.1;
            </p>

            <p>
              <strong>“Contract”</strong> means the contract between the Seller
              and the Buyer for the sale and purchase of the Lot in accordance
              with these Terms and Conditions of Sale;
            </p>

            <p>
              <strong>“Invoice”</strong> means the invoice which is issued by us
              to you in respect of the Contract which incorporates these Terms
              and Conditions of Sale;
            </p>

            <p>
              <strong>“Lot”</strong> means the item offered for sale at the
              Auction;
            </p>

            <p>
              <strong>“Purchase Price”</strong> means the amount indicated on
              the Invoice and which is made up of (i) the highest bid accepted
              by us for the sale of the Lot; and (ii) the Buyer’s Premium;
            </p>

            <p>
              <strong>“Reserve”</strong> means the amount below which we will
              not sell the Lot; and
            </p>

            <p>
              <strong>“Terms and Conditions of Sale”</strong> means the terms
              and conditions set out in this page.
            </p>
          </li>
        </ol>
        <h2>2. Our Role</h2>
        <ol>
          <li>
            We are the Seller and owner of the Lot and are not acting as agent
            for a third party. We are using third party platforms to facilitate
            the Auction, but these platforms are not acting as agent for us and
            shall not be a party to the Contract. If you are the successful
            Bidder and become the Buyer of the Lot, the Contract will be between
            you and us directly.
          </li>
        </ol>
        <h2>3. Registration and Bidding</h2>
        <ol>
          <li>
            In order to bid on the Lot, you will need to create an account on
            www.stevejobsjobapplication.com (the “Website”). As part of the
            registration process, you will need to provide the required
            information and/or complete your bidder profile. By registering for
            a bidder profile, you are declaring that you are at least 18 years
            old. You should ensure that you register well in advance of the
            Auction end date as the registration process is not instant and it
            may take time to fully set up your account.
          </li>

          <li>
            When registering to bid either as an individual or on behalf of an
            entity, you accept that in making a bid, you are accepting personal
            liability to pay the Purchase Price. In order to register to bid,
            you are required to supply information requested to complete our
            bidder registration procedures, including providing a valid debit or
            credit card in your name.
          </li>

          <li>
            The Invoice will be made out to the name of the individual or entity
            provided at registration and we cannot accept funds from an account
            (which includes a digital wallet) in another name. By registering to
            bid, you shall be personally liable for the Purchase Price and any
            other sums due, even if you register in your own name but are acting
            as agent for someone else.
          </li>

          <li>
            We may, in our discretion, require further information/
            documentation from you. Upon registration for the Auction, we shall
            place a temporary hold of $100 on your debit or credit card
            provided, using Stripe (https://stripe.com/gb) which will be
            automatically released within 24 hours of the Auction end date. If
            the credit or debit card details fails, you will not be able to bid
            on the Lot. If you are the successful Bidder, the $100 will still be
            automatically released and will not be set off against the Purchase
            Price. For more information about the payment details and
            information Stripe receives about you please see our
            <router-link :to="{ name: 'PrivacyPolicy' }"
              >Privacy Policy</router-link
            >.
          </li>

          <li>
            We may, at our option, decline to permit you to register as a bidder
            or to accept your bid. If in our opinion you do not satisfy our
            bidder identification and registration procedures we may refuse to
            register you or cancel your bid. If you are the highest bidder after
            the Auction, we may cancel the Contract if we consider that we may
            be exposed to liability, or the bid or source of funds may be linked
            to criminal activity, or we consider that you have breached these
            Terms and Conditions of Sale. We reserve the right to disable or
            deactivate your account at any time during the Auction.
          </li>

          <li>
            Without liability to you, we reserve the right, in our absolute
            discretion: (i) to reject, revoke or refuse to accept any bid (even
            those that have been previously accepted); (ii) restart or continue
            the bidding even if the bidding has finished; (iii) determine the
            successful bidder, cancel the Auction, or re-offer the Lot for sale.
            If any dispute relating to bidding arises during or after the
            Auction, our decision in exercise of this option is final.
          </li>

          <li>
            We do not operate or set bidding limits. You shall be responsible
            for ensuring that you do not place a bid in excess of the amount of
            funds available to you to purchase the Lot if you are ultimately
            successful. Any existing bidding limits on accounts you currently or
            previously held will not apply to this Auction.
          </li>
        </ol>
        <h2>4. Bidding and Currency</h2>
        <ol>
          <li>
            The Lot is subject to a Reserve. Under no circumstances will the
            bidding system place any bid on our behalf at or above the Reserve.
            If you bid under the Reserve amount, you will be notified that your
            bid is below the Reserve and even if you are the highest bidder,
            your bid will not be sufficient to purchase the Lot. Once the
            bidding has exceeded the Reserve amount, notices in relation to the
            Reserve will disappear automatically, and subject to these Terms and
            Conditions of Sale, the Lot will be sold to the highest bidder.
          </li>

          <li>
            Bids may only be submitted whilst the Auction is open, the dates and
            time of which are set out on the Website. Once your bid has been
            submitted and accepted by us, the bid is final and you may not,
            under any circumstances, amend, retract or revoke your bid or
            attempt to do so. We are not responsible for any errors you make in
            bidding or bids which are submitted as a result of fraudulent and/
            or unauthorized instructions. Both you and we shall take reasonable
            precautions against email hacking, forgery and fraud.
          </li>

          <li>
            You will only be able to bid in the fixed bidding increments for
            this Auction which shall be the higher of 5% above the previous bid,
            or $400.
          </li>

          <li>
            The Website will show the current bid in US Dollars (USD) and in
            Ether (ETH). We use http://www.liverates.com/ to show the ETH rate
            but this conversion is for information only and the final sale price
            will be the USD amount. If you are the successful bidder and should
            you choose to pay in ETH, the conversion shall be calculated and
            fixed using https://www.binance.com/en on the date of the Invoice.
          </li>

          <li>
            The time remaining left for the Auction will be displayed as a
            countdown clock on the Website. We use anti-sniping software, so
            that the time remaining for the Auction will be automatically
            extended by ten (10) minutes after each accepted bid. This means
            that there will always be a ten (10) minute period between the last
            bid and end of the Auction, until there are no further bids on the
            Lot for a period of ten (10) minutes.
          </li>

          <li>
            The successful bidder will be the highest bidder when the Auction
            ends, unless we decide in exceptional circumstances to cancel or
            refuse to accept the highest bid for the reasons set out in Clause
            3.5 above, in which case we may determine the next highest bidder as
            the successful bidder. A contract for sale shall be formed
            automatically between the Seller and the successful bidder (as the
            Buyer) at the point the auction closes. We will notify the
            successful bidder by email within 24 hours of the end of the Auction
            and notwithstanding any message that appears on screen
            automatically, only the email notification from us can be relied on
            as confirming that a bidder has been accepted as the Buyer. It is
            your responsibility to ensure that the email address you provided at
            registration is accurate and that you regularly check your email
            (including spam and junk folders) for the email notification, as we
            do not accept responsibility for failed transmission of the
            notification email. We will not notify individual bidders that they
            are not the successful bidder.
          </li>
        </ol>
        <h2>5. Connectivity and Technical Issues</h2>
        <ol>
          <li>
            Some bidders or users of the Website may experience technical
            problems in bidding for the Lot which are beyond our reasonable
            control such as firewalls, loss of internet connection or other
            technical issues with bidding software or their own device. Whilst
            we will endeavour to fix any technical problems that occur on our
            Website or bidding system as quickly as possible, we will not be
            responsible to you for any errors or failures to execute bids, or
            for any errors or omissions in online bidding, including, without
            limitation, errors or failures caused by any loss of connection or
            faults with our bidding software or the Website.
          </li>

          <li>
            Our final record of bids accepted by us will be taken as absolute
            and final in all disputes and our record of sale will prevail in the
            event of a discrepancy between any online records or messages
            provided to you and our record of sale.
          </li>
        </ol>
        <h2>6. The Lot</h2>
        <ol>
          <li>
            Our descriptions of the Lot and the Auction are our opinion only and
            should not be relied on as a statement of fact. All dimensions and
            weights are approximate only. The Lot is accompanied by two (2)
            letters of authenticity which have been prepared by third parties
            which are provided for information, however we cannot make any
            representation, warranty or guarantee as to the authenticity of the
            Lot.
          </li>

          <li>
            The Lot is sold “as is”, and we make no representation or warranties
            as to the condition of the Lot which is sold with all faults,
            inherent defects and deterioration as would be expected of an item
            of the same age and fragility as the Lot. Any statements on our
            Website referring to condition are for information only and cannot
            be relied upon as a guarantee as to the Lot’s condition. Online
            images of the Lot may not show the condition of the Lot clearly as
            different screen resolutions and quality of digital images may
            result in colours and shades appearing differently as the physical
            Lot.
          </li>

          <li>
            We have provided previous sale prices for the benefit of bidders.
            These sale prices are to the best of our knowledge but are not
            intended to form an indication of current or future market value.
          </li>
        </ol>
        <h2>7. Payment and Fees</h2>
        <ol>
          <li>
            If we notify you that you are the successful bidder, the Purchase
            Price you pay for the Lot is the final amount in USD of your highest
            bid accepted by us, together with the Buyer’s Premium, which for
            this Lot is two point five per cent (2.5%) of your final bid. These
            amounts shall be set out on the Invoice issued to you after the
            Auction ends. Due to the changing currency fluctuations between USD
            and ETH, if you have notified us that you wish to pay the Purchase
            Price in ETH, the Purchase Price is payable within 24 hours of us
            issuing the Invoice to you, and if you have elected to pay in USD,
            within five (5) days of us issuing the Invoice (both the “Due
            Date”). If you have not notified us how you wish to pay within 48
            hours of the end of the Auction, we shall issue the Invoice in USD
            only.
          </li>

          <li>
            The Purchase Price is exclusive of all applicable taxes including
            any value added tax (VAT), sales or use tax or equivalent tax. If
            the delivery address for the Lot is in the UK, or if you opt to
            collect the Lot in the UK, we will add VAT to your Invoice
            automatically. It is your responsibility to ensure that you
            ascertain and pay any applicable international duties, custom
            charges, taxes, charges and tariffs owed to the appropriate
            government entity and in the manner prescribed by the laws that you
            are subject.
          </li>

          <li>
            The Invoice will be addressed to the registered bidder and cannot be
            settled by a third party. You can only pay for the Lot in USD or in
            ETH and we cannot accept part payment or mixed currency payments.
            You must notify us of the currency in which you intend to pay within
            48 hours of the end of the Auction.
          </li>

          <li>
            Due to the rise in cyber-attacks, you should confirm the details on
            our Invoice are correct and have not been intercepted by confirming
            the details with us by telephone either at +44 1872 672072 or at +44
            203 7456535 before sending electronic payments as we shall not be
            liable if payment instructions are intercepted, and payments do not
            reach us.
          </li>
        </ol>
        <h2>8. Payment in USD</h2>
        <ol>
          <li>
            If you wish to pay in USD, you must do so by electronic bank
            transfer to the bank details set out on the Invoice. You must ensure
            that any bank charges, transfer fees or currency conversions are
            added on top of the amount you pay to us, as you are responsible for
            these charges.
          </li>
        </ol>
        <h2>9. Payment in ETH</h2>
        <ol>
          <li>
            If you wish to pay the Purchase Price in ETH, you must send the
            precise amount of ETH stated on the Invoice to our digital wallet
            address as set out on the Invoice. You must ensure that the amount
            of ETH and our digital wallet address are quoted exactly, otherwise
            we will not be able to reconcile your payment and we are not liable
            if the payment does not reach us.
          </li>

          <li>
            Once the transaction has been confirmed on the Ethereum network, we
            will send you an email confirmation of payment to confirm our
            receipt of the Purchase Price by you. Please note that an Ethereum
            transaction can take anywhere from 15 seconds to multiple days,
            depending on network congestion and the gas fees paid by you. You
            are responsible for ensuring that your payment reaches us by the Due
            Date. You are also responsible for applicable gas fees that apply to
            the transaction. We do not take responsibility for network
            congestion or high gas prices and we cannot accept this as a reason
            for you to delay payment of the Purchase Price and other sums due.
          </li>
        </ol>
        <h2>10. Taxes and VAT</h2>
        <ol>
          <li>
            If your purchase of the Lot qualifies as a valid export sale and we
            have not added VAT to the Invoice as a result, you shall, and shall
            procure that your shippers or representatives comply with all
            requirements of HM Revenue & Customs in completing and providing all
            necessary documentation, including proofs of export and Bills of
            Lading, to us within the prescribed time limits and within 90 days
            from the date of payment at the latest. You hereby indemnify and
            hold us harmless against any claims or costs made against or
            incurred by us for VAT or any other costs, expenses, liabilities,
            demands, penalties incurred by reason of your failure to comply with
            the formalities referred to in this Clause 10.1 concerning sale for
            export.
          </li>
        </ol>
        <h2>11. Your Warranties</h2>
        <ol>
          <li>
            You warrant, represent and undertake to us (as applicable), at the
            time of registration and in respect of each bid you make, that:
            <ol>
              <li>
                the funds used to bid to purchase the Lot are not connected with
                any criminal activity, including tax evasion;
              </li>

              <li>
                you are not the subject of any investigation, nor have you been
                charged with or convicted of money laundering offences,
                terrorist activities or other crimes and you are not subject to
                sanctions in the UK;
              </li>

              <li>
                all information you provide to us at registration or otherwise,
                is true and complete;
              </li>

              <li>
                you will communicate with us in good faith regarding the details
                required for the Invoice, collection/ delivery address and
                method, payment and currency and transferring possession of the
                Lot to you; and
              </li>

              <li>
                you have acknowledged and agreed to the post sale royalty set
                out at Clause 15.1 below and will use your best endeavours to
                ensure any future buyers of the Lot comply with and pay to us
                the same royalty.
              </li>
            </ol>
          </li>
        </ol>
        <h2>12. Title and Risk</h2>
        <ol>
          <li>
            Title to the Lot shall not pass to you until we have received full
            payment of the Purchase Price and any additional amounts due in
            cleared funds following our issuing of an Invoice to you. We shall
            only transfer to you the same title, right and interest that we have
            in the Lot.
          </li>

          <li>
            Risk in the Lot shall pass to you from the earlier of (i) collection
            of the Lot by you, or delivery to you if we have agreed to deliver
            the Lot to you at your cost; or (ii) five (5) days from the Due
            Date, regardless of whether or not payment has been made.
          </li>
        </ol>
        <h2>13. Late Payment</h2>
        <ol>
          <li>
            If you fail to pay the Purchase Price in full (together with any
            additional fees, taxes or the Buyer’s Premium) by the Due Date, we
            shall be entitled to charge interest on the overdue amount at the
            rate of three per cent (3%) per annum above the Bank of England Bank
            Rate from time to time and to charge for our reasonable storage
            charges.
          </li>

          <li>
            Notwithstanding our right to charge interest and storage fees, we
            shall also be entitled, in addition to enforcing any legal rights
            and remedies we may have (including issuing legal proceedings
            against you) to cancel the sale of the Lot to you and to re-sell the
            Lot in any manner we think appropriate. In the event that we cancel
            and re-sell the Lot, you shall still be liable to us for any
            shortfall between the Purchase Price and the proceeds from any
            resale plus any and all costs, expenses, losses, damages and legal
            fees incurred by us as a result of your failure to pay the Purchase
            Price by the Due Date.
          </li>
        </ol>
        <h2>14. Transport and Storage</h2>
        <ol>
          <h3>Collection</h3>

          <li>
            After receipt of the Purchase Price and any sums due to us, we will
            make the Lot available to you for collection at a bank vault in
            South West London (the details of which shall be provided to you) on
            a mutually convenient time and date to be agreed in advance between
            us. You must provide us with 24 hours’ notice and arrange collection
            during normal business hours. If you wish to send someone to collect
            the Lot on your behalf, we may need to ask you for the name and ID
            of that person before we can authorise collection. Failure to
            provide this information may delay collection.
          </li>

          <li>
            We shall store the Lot at our cost for a period of five (5) days
            after payment, after which if you do not collect the Lot, we shall
            be entitled in our discretion, to:
            <ol>
              <li>charge you reasonable storage and insurance costs;</li>

              <li>ship the Lot to you at your expense;</li>

              <li>move the Lot to one of our other storage facilities; or</li>

              <li>
                sell the Lot in any way we think appropriate. If we exercise our
                right to sell the Lot will use the proceeds of the sale against
                any amounts you owe us and we will pay any amount left from that
                sale to you. If there is a shortfall, you must pay us any
                difference between the amount we have received from the sale and
                the amount you owe us.
              </li>
            </ol>
          </li>
          <h3>Delivery</h3>
          <li>
            If requested and agreed with us, we shall deliver the Lot to you at
            the billing address that you provided at registration or to an
            alternative delivery address that you provide to us in writing in
            advance of us issuing the Invoice to you. You will be responsible
            for all shipping and transport costs, including handling fees and
            packaging, which must be paid to us in advance of shipping. You are
            also responsible for payment of any customs duties, import tax,
            tariffs or other third party costs required to facilitate delivery
            of the Lot to you, and shall be required to pay such costs to the
            relevant authority in the manner described by law.
          </li>
          <li>All delivery times are estimates only.</li>
        </ol>
        <h2>15. Post-Sale Restrictions and Re-Sale</h2>
        <ol>
          In the event of a future sale of the Lot, you agree that you shall
          notify us of the sale in advance using the contact details provided to
          you on the Invoice and undertake to pay to us ten per cent (10%) of
          the net sale proceeds within seven (7) days of receipt of the sale
          proceeds in the same currency as the sale proceeds to our nominated
          account (or digital wallet as the case may be). We undertake to donate
          one per cent (1%) of the royalty (or USD equivalent if received in
          ETH) the same charities to whom we donated funds from the original
          Purchase Price, or if no longer in existence, to any other charity in
          our discretion. You undertake that in your sale of the Lot, you shall
          use your best endeavours to procure that the onward purchaser is bound
          by the same terms as set out in this Clause 15 and shall undertake to
          bind future buyers to the same post sale obligations.
        </ol>
        <h2>16. Right to Cancel the Purchase of a Lot</h2>
        <ol>
          <li>
            If you are a Consumer pursuant to the EU Consumer Rights Directive
            and habitually reside in the UK or European Union you have the right
            to cancel the purchase without cause at any time up to the end of 14
            calendar days after the date that the Lot is delivered to you, or
            made available for collection (the “Cancellation Period”). You must
            notify us of your decision to cancel by a clear statement (e.g. a
            letter sent by post or email), before the Cancellation Period
            expires. A model cancellation form is set out below. If you cancel
            the sale during the Cancellation Period, we will reimburse the
            Purchase Price without undue delay, and no later than (i) 14
            calendar days after the day we receive the Lot; or (ii) (if earlier)
            14 calendar days after the date you provide evidence that the Lot
            has been shipped. Reimbursement shall be made using the same payment
            method as the initial purchase.
          </li>

          <li>
            You must send back the Lot or hand deliver it to the address
            specified by us, without undue delay and in any event no later than
            14 calendar days from the day on which you notify us of
            cancellation. You shall bear the cost of returning the Lot,
            including any shipping, packing, insurance and import duties
            incurred as a result of the return. You are liable for any
            diminished value of the Lot if handling of the Lot went beyond what
            was necessary to establish the nature and characteristics of the
            Lot. If the Lot is returned damaged, we will deduct from the refund
            a reasonable amount for the cost of repair or loss in value
            resulting from such damage.
          </li>
        </ol>
        <h2>17. Our Liability to you</h2>
        <ol>
          <li>
            Nothing in these Terms and Conditions of Sale limits or excludes our
            liability for: (i) death or personal injury caused by negligence; or
            (ii) fraud or fraudulent misrepresentation.
          </li>

          <li>
            We do not give any representation, warranty or guarantee or assume
            any liability of any kind in respect of the Lot’s merchantability,
            fitness for purpose, quality, description, condition, authenticity,
            rarity, importance, provenance, history, value, marketability or
            historical relevance and save for where Clause 17.5 applies, we
            exclude any liability for breach of any term, warranty or condition
            which may be implied by the Sale of Goods Act 1979 and the Supply of
            Goods and Services Act 1982.
          </li>

          <li>
            We are not liable (i) for the statements, data, information and
            opinions of others including those set out in the authenticity
            certificates provided or previous sales records for the Lot or those
            of our representatives or employees; (ii) for any deterioration of
            the Lot after the Auction; and (iii) in circumstances where you fail
            to comply with any reasonable instructions on the handling,
            safekeeping or maintenance of the Lot.
          </li>

          <li>
            Notwithstanding the above, if we are found to be liable to you, our
            total liability in contract, tort (including negligence or breach of
            statutory duty), misrepresentation or otherwise, arising in
            connection with our contract for sale with the Buyer pursuant to
            these Terms and Conditions of Sale, shall be limited to the Purchase
            Price. We shall not be liable for any loss of profits, loss of
            business, goodwill, loss of anticipated savings or for any special,
            indirect, incidental, or consequential loss, costs, damages, charges
            or expenses to the fullest extent permitted by law.
          </li>

          <li>
            If the Buyer is a Consumer: We shall not be liable for any loss or
            damage that is not foreseeable (loss or damage is foreseeable if it
            is an obvious consequence of our breach or if it was contemplated by
            us and the Buyer at the time the Contract was entered into).
          </li>
        </ol>
        <h2>18. General</h2>
        <ol>
          <li>
            We shall not be responsible for any failure to meet any obligation
            which we, or you have under these Terms and Conditions of Sale which
            is caused by circumstances beyond our, or your reasonable control.
            This includes, but is not limited to strikes, lock-outs, fire,
            flood, natural disasters, epidemics, wars, armed conflict, terrorist
            attacks and nuclear and chemical contamination.
          </li>

          <li>
            We or third parties may own the copyright in all images,
            illustrations and written material produced by or for us relating to
            the Lot. You cannot use them without our prior written permission.
            In purchasing the Lot, you will not gain any copyright or other
            reproduction rights to the Lot.
          </li>

          <li>
            We will hold and process your personal information and may share it
            in line with, and for uses as described in, our Privacy Policy
            published on our Website
            <router-link :to="{ name: 'PrivacyPolicy' }">here</router-link> or
            available on request by email to info@stevejobsjobapplication.com.
          </li>

          <li>
            If a court finds that any part of these Terms and Conditions of Sale
            is not valid, or is illegal or impossible to enforce, that part of
            these Terms and Conditions of Sale will be treated as being deleted,
            and the rest of these Terms and Conditions of Sale will not be
            affected.
          </li>

          <li>
            You may not grant a security over or transfer your rights or
            responsibilities under these Terms and Conditions of Sale or the
            Contract unless we have given our written permission. These Terms
            and Conditions of Sale will be binding on your successors, estate
            and anyone who takes over your rights and responsibilities. No term
            of these Terms and Conditions of Sale shall be enforceable under the
            Contracts (Rights of Third Parties) Act 1999 by anyone other than
            you.
          </li>

          <li>
            No failure or delay to exercise any right or remedy provided under
            these Terms and Conditions of Sale shall constitute a waiver of that
            or any other right or remedy, nor shall it prevent or restrict the
            further exercise of that or any other right or remedy. No single or
            partial exercise of such right or remedy shall prevent or restrict
            the further exercise of that or any other right or remedy.
          </li>

          <li>
            Any notice pursuant to or in connection with these Terms and
            Conditions of Sale shall be in writing and delivered by hand or by
            post to our registered office or to
            info@stevejobsjobapplication.com, and in the case of us sending a
            notice to you, to the email address or postal address provided to us
            by you on your bidding profile via our Website. Notices are deemed
            delivered on delivery if by hand or the third day after posting, or
            if delivered by email, immediately upon transmission if transmitted
            during normal business hours in the country of the recipient, or
            otherwise on the following business day.
          </li>

          <li>
            These Terms and Conditions of Sale and any contractual or
            non-contractual dispute arising out of or in connection with these
            Terms and Conditions of Sale, will be governed and construed in
            accordance with the law of England and Wales. Each party irrevocably
            agrees, for the sole benefit of the Seller that, subject as provided
            below, the courts of England and Wales shall have exclusive
            jurisdiction over any dispute or claim (including non-contractual
            disputes or claims) arising out of or in connection with this
            agreement or its subject matter or formation. Nothing in this clause
            shall limit our right to take proceedings against you in any other
            court of competent jurisdiction, nor shall the taking of proceedings
            in any one or more jurisdictions preclude the taking of proceedings
            in any other jurisdictions, whether concurrently or not, to the
            extent permitted by the law of such other jurisdiction.
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SaleTerms",
  created() {
    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.goBack();
      }
    });
  },
  unmounted() {
    document.removeEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.goBack();
      }
    });
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
});
</script>

<style scoped lang="scss">
.policy {
  font-size: 0.8rem;
}
</style>
