
import { defineComponent } from "vue";
import { Price } from "@/types/price";
import EndingTime from "@/mixins/EndingTime";
import ModalWindow from "@/components/ModalWindow.vue";
import { mapGetters, mapState } from "vuex";

export default defineComponent({
  name: "AuctionDetail",
  components: {
    ModalWindow,
  },
  mixins: [EndingTime],
  props: {
    auctionData: {
      type: Object as () => Price,
      default: () => ({
        priceETH: 0,
        priceUSD: 0,
        endTimestamp: { seconds: 0 },
        bidsNumber: 0,
        reserveMet: false,
      }),
    },
    isDigital: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapState(["currencyPrices"]),
    ...mapGetters(["physicalEndTime", "digitalEndTime"]),
    secondsToEnd(): number {
      if (this.isDigital) {
        return this.digitalEndTime;
      } else {
        return this.physicalEndTime;
      }
    },
    remainingTime(): {
      days: string;
      hours: string;
      minutes: string;
      seconds: string;
    } {
      return this.formatTime(this.secondsToEnd);
    },
  },
});
