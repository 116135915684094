
import { defineComponent } from "vue";
import VueScrollTo from "vue-scrollto";

export default defineComponent({
  name: "PrivacyPolicy",
  directives: {
    scrollTo: VueScrollTo,
  },
  created() {
    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.goBack();
      }
    });
  },
  unmounted() {
    document.removeEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.goBack();
      }
    });
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
});
