<template>
  <h2>Poll</h2>
  <div class="poll__wrapper">
    <h3>Which do you think will have more value?</h3>
    <div class="poll__result">
      <div class="poll__result__description">Physical</div>
      <div class="poll__result__middle">
        {{ physicalPerc }}%<span class="divider"></span>{{ digitalPerc }}%
      </div>
      <div class="poll__result__description">Digital</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "AssetsPoll",
  data() {
    return {
      voted: false,
    };
  },
  computed: {
    ...mapState(["poll"]),
    physicalPerc(): number {
      if (this.poll.physical === 0) {
        return 0;
      }
      return parseInt(
        (
          (this.poll.physical / (this.poll.digital + this.poll.physical)) *
          100
        ).toFixed(0)
      );
    },
    digitalPerc(): number {
      if (this.poll.digital === 0) {
        return 0;
      }
      return 100 - this.physicalPerc;
    },
  },
});
</script>

<style lang="scss">
.poll {
  &__wrapper {
    padding: 2rem 0.5rem;
    margin-top: 0.6rem;
    margin-bottom: 8rem;
    border: 1px solid $colorBlack;
    @include media-breakpoint-up(sm) {
      padding: 2rem 4.5rem;
    }

    h3 {
      font-size: 2rem;
    }

    h4 {
      font-size: 2rem;
    }
    p {
      margin-top: 2rem;
      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }
    }
  }

  &__buttons {
    margin-top: 1rem;
    margin-bottom: 1rem;

    .button {
      &:first-of-type {
        margin-right: 2rem;
      }
    }
  }

  &__result {
    display: flex;
    margin: 1rem 0;
    justify-content: center;
    font-family: $fontTitle;
    flex-direction: column;
    line-height: 2rem;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      line-height: 5rem;
    }

    &__description {
      margin: 0 2rem;
      font-size: 2rem;
    }

    &__middle {
      font-size: 5rem;
      line-height: 5rem;

      .divider {
        display: block;
        background: $colorBlack;
        margin: 0.5rem auto;
        width: 50%;
        height: 1px;
        @include media-breakpoint-up(sm) {
          height: 4rem;
          display: inline-block;
          width: 1px;
          margin: 0 2rem;
          position: relative;
          top: 0.5rem;
        }
      }
    }
  }
}
</style>
