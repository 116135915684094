<template>
  <div>
    {{ lotPrices.physical }}<br />
    {{ lotPrices.digital }}<br />
    {{ currencyPrices }}<br />
    Digital end time: {{ digitalEndTime }}<br />
    Physical end time: {{ physicalEndTime }}<br />
    Total end time: {{ totalEndTime }}<br />
    Price Ratio: {{ priceRatio }}%<br />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";

export default defineComponent({
  name: "Test",
  computed: {
    ...mapState(["lotPrices", "currencyPrices"]),
    ...mapGetters(["digitalEndTime", "physicalEndTime", "totalEndTime"]),
    priceRatio: function () {
      const digitalPrice = Math.max(
        1,
        this.lotPrices.digital.priceETH * this.currencyPrices.ethPrice
      );
      const physicalPrice = Math.max(1, this.lotPrices.physical?.priceUSD);
      return Math.floor(
        (1 - digitalPrice / (physicalPrice + digitalPrice)) * 100
      );
    },
  },
});
</script>
