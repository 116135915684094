w
<template>
  <header class="page__header">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <router-link
            :to="{ name: 'Home', hash: '#footer' }"
            class="page__header__link condensed"
            >Back</router-link
          >
        </div>
      </div>
    </div>
  </header>
  <div class="container page policy">
    <div class="row">
      <div class="col">
        <h1 class="text-center">Cookies Policy</h1>
        <p>
          Our website uses cookies to distinguish you from other users of our
          website. This helps us to provide you with a good experience when you
          browse our website and also allows us to improve our site.
        </p>
        <p>
          A cookie is a small file of letters and numbers that we store on your
          browser or the hard drive of your computer if you agree. Cookies
          contain information that is transferred to your computer's hard drive.
        </p>
        <p>We use the following cookies:</p>
        <ul>
          <li>
            <strong>Strictly necessary cookies</strong>. These are cookies that
            are required for the operation of our website. They include, for
            example, cookies that enable you to log into secure areas of our
            website, use a shopping cart or make use of e-billing services.
          </li>
          <li>
            <strong>Analytical or performance cookies</strong>. These allow us
            to recognise and count the number of visitors and to see how
            visitors move around our website when they are using it. This helps
            us to improve the way our website works, for example, by ensuring
            that users are finding what they are looking for easily.
          </li>
          <li>
            <strong>Functionality cookies</strong>. These are used to recognise
            you when you return to our website. This enables us to personalise
            our content for you, greet you by name and remember your preferences
            (for example, your choice of language or region).
          </li>
          <li>
            <strong>Targeting cookies</strong>. These cookies record your visit
            to our website, the pages you have visited and the links you have
            followed. We will use this information to make our website and the
            advertising displayed on it more relevant to your interests. We may
            also share this information with third parties for this purpose.
          </li>
        </ul>
        <p>
          You can find more information about the individual cookies we use and
          the purposes for which we use them in the table below:
        </p>
        <table>
          <tr>
            <th>Cookie Title<br />Cookie Name</th>
            <th>Purpose</th>
            <th>More information</th>
          </tr>
          <tr>
            <td>sjja_</td>
            <td>
              Type: First party.<br />
              Duration: 30 days<br />
              Cookies with this prefix are strictly necessary and support
              essential functionality of the website.
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              __stripe_mid<br />
              __stripe_sid
            </td>
            <td>
              Type: Third party.<br />
              Duration: up to 1 year<br />
              This cookie is essential for our site to:<br />
              Set for fraud prevention purposes and helps us assess the risk
              associated with an attempted transaction. Learn more about.
            </td>
            <td>
              <a href="https://stripe.com/en-gb/cookie-settings" target="_blank"
                >More</a
              >
            </td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>
              Type: Third party.<br />
              Duration: Session<br />
              When using the standard IAP login flow, the user receives a
              session cookie that references their Google login session. IAP
              uses this cookie to confirm that the user is still signed in. IAP
              requires a user to sign in before accessing a IAP-secured app.
            </td>
            <td>
              <a
                href="https://cloud.google.com/iap/docs/sessions-howto"
                target="_blank"
                >More</a
              >
            </td>
          </tr>
        </table>
        <p>
          Please note that the following third parties may also use cookies,
          over which we have no control. These named third parties may include,
          for example, advertising networks and providers of external services
          like web traffic analysis services. These third party cookies are
          likely to be analytical cookies or performance cookies or targeting
          cookies:
        </p>
        <ul>
          <li>Stripe</li>
          <li>Google (IAP)</li>
          <li>Zendesk</li>
        </ul>
        <p>
          You can block cookies by activating the setting on your browser that
          allows you to refuse the setting of all or some cookies. However, if
          you use your browser settings to block all cookies (including
          essential cookies) you may not be able to access all or parts of our
          website.
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CookiesPolicy",
  created() {
    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.goBack();
      }
    });
  },
  unmounted() {
    document.removeEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.goBack();
      }
    });
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
});
</script>

<style scoped lang="scss">
.policy {
  font-size: 0.8rem;

  ol {
    font-weight: bold;
  }

  table {
    margin-bottom: 1rem;
    tr {
      th,
      td {
        border: 1px solid $colorGrey;
        padding: 0.2rem;
        vertical-align: top;
      }
    }
  }
}
</style>
