<template>
  <transition name="slide" appear>
    <div v-if="firstVisit && showConsent" class="consent__wrapper">
      <div class="consent">
        <p>
          Our website uses cookies. A cookie is a small file of letters and
          numbers that we put on your computer if you agree. These cookies allow
          us to distinguish you from other users of our website, which helps us
          to provide you with a good experience when you browse our website and
          also allows us to improve our site. (The cookies we use are
          "analytical" cookies.They allow us to recognise and count the number
          of visitors and to see how visitors move around the site when they are
          using it. This helps us to improve the way our website works,for
          example by ensuring that users are finding what they are looking for
          easily.) Read more about the individual cookies we use and how to
          recognise.
        </p>
        <div class="consent__buttons">
          <router-link
            :to="{ name: 'CookiesPolicy' }"
            class="button secondary smallest"
            ><span>Read more</span></router-link
          >
          <a href="#" class="button primary smallest" @click.prevent="setCookie"
            ><span>Accept</span></a
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CookieConsent",
  data() {
    return {
      showConsent: true,
    };
  },
  computed: {
    firstVisit(): boolean {
      return !this.$cookies.isKey("sjja-cookie-consent");
    },
  },
  methods: {
    setCookie() {
      this.$cookies.set("sjja-cookie-consent", "1");
      this.showConsent = false;
    },
  },
});
</script>

<style lang="scss">
.consent {
  background: $colorWhite;
  box-shadow: -1px -3px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 780px;
  padding: 10px;

  p {
    font-size: 0.8rem;
  }

  &__wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
    display: flex;
    justify-content: center;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  bottom: -250px;
}
</style>
