<template>
  <header class="page__header">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <router-link
            :to="{ name: 'Home', hash: '#footer' }"
            class="page__header__link condensed"
            >Back</router-link
          >
        </div>
      </div>
    </div>
  </header>
  <div class="container page policy">
    <div class="row">
      <div class="col">
        <h1 class="text-center">Website Terms of Use</h1>
        <p>
          Please read these Terms of Use carefully before using this Website.
        </p>
        <h2>Important information and who we are</h2>
        <p>
          <router-link :to="{ name: 'Home' }">(The “Website”)</router-link> is
          owned and operated by Winthorpe Ventures LLP (“Firm”, "we", "us" or
          "our"), registered in England and Wales at 71-75 Shelton Street Covent
          Garden London WC2H 9JQ, UK with registered number OC436607. The
          Website will host an auction for the sale of a physical asset
          (“Auction”) and enable account holders to place bids to purchase the
          asset. The Website will also contain a tracking feature for users to
          compare the Auction to a simultaneous auction of a NFT (non-fungible
          token) of the physical asset hosted by Rarible (https://rarible.com).
        </p>
        <p>
          These terms of use for the Website (“Terms of Use”) apply to anyone
          who uses the Website regardless of whether or not they register for a
          bidding account (“Account”), place a bid on the Website, sign up to
          receive newsletters or simply track the two auctions. By using the
          Website, you confirm that you accept and agree to comply with these
          Terms of Use. If you have any questions about our Terms of Use or on
          how to use the Website, register for an Account, place a bid, sign up
          to receive newsletters or track the two auctions , please contact us
          at info@stevejobsjobapplication.com or write to us at the above
          registered address.
        </p>
        <h2>Changes to these Terms of Use</h2>
        <p>
          We may amend these Terms of Use from time to time. Every time you wish
          to use our Website, please check these Terms of Use to ensure you
          understand the terms that apply at that time. If the changes are
          material, we will post a notice on our Website before the changes go
          into effect and will notify users who have provided us with their
          email addresses by email. These Terms of Use were last updated on 19th
          July 2021.
        </p>
        <h2>Other terms that may apply to you</h2>
        <p>
          Separate Terms and Conditions of Sale set out the legally binding
          agreements between you and us when you place a bid for the physical
          asset and the NFT and you should read the Terms and Conditions of Sale
          for the relevant asset on which you intend to bid, before placing a
          bid. Our
          <router-link :to="{ name: 'PrivacyPolicy' }"
            >Privacy Policy</router-link
          >
          and
          <router-link :to="{ name: 'CookiesPolicy' }"
            >Cookies Policy</router-link
          >
          set out the terms on which we process any personal data we collect
          from you and which cookies we collect.
        </p>
        <h2>Registering for an Account</h2>
        <p>
          While the general use of our Website does not require registration,
          you are required to register for an Account to bid on the physical
          asset at our
          <router-link :to="{ name: 'Register' }">Auction</router-link> and you
          will need to register for an account with
          <a
            href="https://rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:1134816"
            target="_blank"
            >Rarible</a
          >
          to bid on the NFT digital asset. You warrant that the personal
          information which you are required to provide on registration and when
          placing a bid is true, accurate and current in all respects. You are
          responsible for keeping the information you provide up to date.
        </p>
        <p>
          You must keep your password or any other piece of information that is
          part of our security procedure safe and treat such information as
          confidential. You must not disclose it to any third party. If you know
          or suspect that anyone other than you knows your password, you must
          promptly notify us at the above contact details.
        </p>
        <h2>Intellectual Property</h2>
        <p>
          The Website and all the content therein are protected by intellectual
          property rights, including copyright and trade marks. By using the
          Website, you acknowledge that we are the owner or licensee of all
          intellectual property rights in the Website. You acknowledge that the
          Website may only be used according to these Terms of Use. You
          acknowledge that the Website and all content, relevant trade marks,
          and any other portion thereof may not be used, reproduced, duplicated,
          copied, sold, resold, accessed, modified or otherwise exploited, in
          whole or in part, for any purpose without our written consent. All
          intellectual property rights in the Website and the content are hereby
          reserved and our Moral Rights are hereby asserted.
        </p>
        <p>
          Any unauthorised use of the Website or content may violate
          intellectual property laws, privacy laws, communications regulations,
          statutes and treaties across the world. If you breach these Terms of
          Use, your right to use our Website will cease immediately and you
          must, at our option, return or destroy the unauthorised copies of the
          materials you have made.
        </p>
        <p>
          By using our Website (including registering for an Account, or placing
          a bid), you do not acquire any intellectual property rights in any of
          the content on our Website, including images of the physical asset or
          NFT digital asset.
        </p>
        <h2>User Conduct</h2>
        <p>
          You shall not circumvent, remove, alter, deactivate, degrade or thwart
          any of the Website protections; use any robot, spider, scraper or
          other automated means to access the content; decompile, reverse
          engineer or disassemble any software or other products or processes
          accessible through the Website; or insert any code or product or
          manipulate the Website in any way or use any data mining, data
          gathering or extraction method.
        </p>
        <p>
          You may link to our Website, provided you do so in a way that is fair
          and legal and which does not damage our reputation or take advantage
          of it. In particular, you must not establish a link in such a way as
          to suggest any form of association, approval or endorsement on our
          part where none exists. Our Website must not be framed on any other
          website. We reserve the right to withdraw linking permission without
          notice. If you wish to make any use of material on our Website other
          than that set out above, please address your request to us in writing.
        </p>
        <h2>Changes and Access to the Website</h2>
        <p>
          We may change, modify or close down the Website and the Website may
          become static after the close of the Auction. We may suspend or
          withdraw or restrict the availability of all or any part of our site
          for business and operational reasons. Whilst we try to ensure that the
          Website is accessible at all times, access may be suspended
          temporarily and without notice in the case of system failure,
          maintenance or repair, or for reasons beyond our control. We shall not
          be liable if for any reason the Website is unavailable at any time or
          for any period.
        </p>
        <h2>Bidding</h2>
        <p>
          Please note, all bidding activity for the physical asset will take
          place <router-link :to="{ name: 'Lot' }">here</router-link> and all
          bidding activity for the NFT will take place on
          <a
            href="https://rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:1134816"
            target="_blank"
            >Rarible</a
          >. If you wish to place a bid, you should navigate to the bidding
          pages to do so. If this Website is unavailable at any time or if the
          links are temporarily disabled or malfunctioning, you can reach each
          bidding page directly by typing the following addresses into your
          browser https://stevejobsjobapplication.com/snoofa/register and
          https://rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:1134816.
          Whilst we do our best to keep the tracking feature up to date in real
          time with both auctions, we do not guarantee the speed, accuracy or
          quality of any content on the Website and the tracking feature may at
          points be out of sync with the bidding activity on both the Website’s
          Auction page and the auction for the NFT digital asset occurring on
          Rarible. For the most up to date bids on each asset, you should view
          the bidding page for that asset directly.
        </p>
        <h2>No reliance on information</h2>
        <p>
          No content on our Website is intended to amount to advice and we shall
          not be liable for any loss caused by your reliance on any information
          on the Website or its content relating to the bidding activity. Please
          obtain professional or specialist advice before placing, or refraining
          from placing a bid at either auction.
        </p>
        <h2>Links to third party websites</h2>
        <p>
          Our Website contains links to various websites and other resources
          provided by third parties. These links are provided for your
          information only. We have no control over the contents of those
          third-party websites or resources and accept no responsibility for
          them or for any loss or damage that may be suffered in connection with
          your use of them. Our Privacy Policy sets out how these third parties
          process your personal data.
        </p>
        <h2>Limitation of Liability</h2>
        <p>
          Save for (i) death or personal injury caused by our negligence, (ii)
          fraud and/or fraudulent misrepresentation and (iii) consumer legal
          rights which cannot be excluded by law, we exclude all liability in
          contract, tort (including negligence), for breach of statutory duty,
          or otherwise; for any loss of profit or indirect or consequential loss
          arising under or in connection with these Terms of Use or the use of
          the Website.
        </p>
        <p>
          We shall not be held liable for damage due to viruses that may infect
          your computer equipment, software, data or other property on account
          of your access to or use of the Website except for where the same is
          caused by our breach of these Terms of Use. We use reasonable
          endeavours to ensure that the Website is free of viruses and other
          types of malicious code. However, when using the Website you should
          regularly check for malware.
        </p>
        <p>
          Our liability in relation to the sale of the physical asset sold
          through the Auction on our Website will be as set out in the Terms and
          Conditions of Sale.
        </p>
        <h2>Miscellaneous</h2>
        <p>
          If a court finds that any part of these Terms of Use are not valid, or
          is illegal or impossible to enforce, that part of these Terms of Use
          will be treated as being deleted, and the rest of these Terms of Use
          will not be affected.
        </p>
        <p>
          We shall not be responsible for any failure to meet any obligation
          which we, or you have under these Terms of Use which is caused by
          circumstances beyond our, or your reasonable control. This includes,
          but is not limited to strikes, lock-outs, fire, flood, natural
          disasters, epidemics, wars, armed conflict, terrorist attacks and
          nuclear and chemical contamination.
        </p>
        <p>
          Any notice pursuant to or in connection with these Terms of Use shall
          be in writing and delivered by hand or by post to our registered
          office or to info@stevejobsjobapplication.com, and in the case of us
          sending a notice to you, to the email address or postal address
          provided to us by you on your Account via our Website. Notices are
          deemed delivered on delivery if by hand or the third day after
          posting, or if delivered by email, immediately upon transmission if
          transmitted during normal business hours in the country of the
          recipient, or otherwise on the following business day.
        </p>
        <p>
          These Terms of Use and any contractual or non-contractual dispute
          arising out of or in connection with these Terms of Use, will be
          governed and construed in accordance with the law of England and
          Wales. Each party irrevocably agrees, for the sole benefit of the Firm
          that, subject as provided below, the courts of England and Wales shall
          have exclusive jurisdiction over any dispute or claim (including
          non-contractual disputes or claims) arising out of or in connection
          with this agreement or its subject matter or formation. Nothing in
          this clause shall limit our right to take proceedings against you in
          any other court of competent jurisdiction, nor shall the taking of
          proceedings in any one or more jurisdictions preclude the taking of
          proceedings in any other jurisdictions, whether concurrently or not,
          to the extent permitted by the law of such other jurisdiction.
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AuctionTerms",
  created() {
    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.goBack();
      }
    });
  },
  unmounted() {
    document.removeEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.goBack();
      }
    });
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
});
</script>

<style scoped lang="scss">
.policy {
  font-size: 0.8rem;
}
</style>
