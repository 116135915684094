
import { defineComponent } from "vue";

export default defineComponent({
  name: "ZoomWindow",
  emits: ["close"],
  data() {
    return {
      dragging: false,
    };
  },
  computed: {
    maskWidth(): number {
      return (this.$refs.mask as HTMLElement).offsetWidth;
    },
    maskHeight(): number {
      return (this.$refs.mask as HTMLElement).offsetHeight;
    },
    imgWidth(): number {
      return (this.$refs.image as HTMLElement).offsetWidth;
    },
    imgHeight(): number {
      return (this.$refs.image as HTMLElement).offsetHeight;
    },
  },
  created() {
    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.dragging = false;
        this.$emit("close");
      }
    });
  },
  unmounted() {
    document.removeEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.dragging = false;
        this.$emit("close");
      }
    });
  },
  methods: {
    dragImage(e: MouseEvent) {
      if (this.dragging) {
        let relX, relY, img, maskRect, mask;
        mask = this.$refs.mask as HTMLElement;
        if (mask === null) return;
        maskRect = mask.getBoundingClientRect();
        img = this.$refs.image as HTMLElement;
        relX = e.x - maskRect.x;
        relY = e.y - maskRect.y;
        img.style.left =
          "-" +
          ((100 * relX) / this.maskWidth) *
            (this.imgWidth / this.maskWidth - 1) +
          "%";
        img.style.top =
          "-" +
          ((100 * relY) / this.maskHeight) *
            (this.imgHeight / this.maskHeight - 1) +
          "%";
      }
    },
  },
});
