export default {
  methods: {
    formatTime(
      seconds: number
    ): {
      days: string;
      hours: string;
      minutes: string;
      seconds: string;
    } {
      if (seconds <= 0) {
        return { days: "00", hours: "00", minutes: "00", seconds: "00" };
      }
      const d = ("0" + Math.floor(seconds / (60 * 60 * 24))).slice(-2);
      const h = (
        "0" + Math.floor((seconds % (60 * 60 * 24)) / (60 * 60))
      ).slice(-2);
      const m = ("0" + Math.floor((seconds % (60 * 60)) / 60)).slice(-2);
      const s = ("0" + Math.floor(seconds % 60)).slice(-2);
      return { days: d, hours: h, minutes: m, seconds: s };
    },
  },
};
