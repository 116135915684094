
import { defineComponent } from "vue";
import FooterBlock from "@/components/FooterBlock.vue";

export default defineComponent({
  name: "Snoofa",
  components: {
    FooterBlock,
  },
});
